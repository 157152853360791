import { EmailTemplateTypes, PolicyType } from "shared";
import { useMemo } from "react";

import { TextEditor } from "../../../../Molecules/TextEditor";
import { useMergeTags } from "../../../../../hooks/useMergeTagsAndLinks";
import { FormLabel } from "../../../../../../common/Atoms/FormLabel";

import { IFieldProps } from "./fieldProps";

export function InvoiceReference({
  policyFormData,
  handleChange,
}: IFieldProps) {
  const templateType = policyFormData.policy_type === PolicyType.LATE_FEE ? EmailTemplateTypes.INVOICE_REFERENCE : EmailTemplateTypes.INVOICE_REFERENCE_STATEMENT_CHARGE  ;
  const mergeTags = useMergeTags(templateType);

  const formattedMergeTags = useMemo(() => {
    return mergeTags.asArray
      .map(tag => {
        return {
          text: tag.name,
          value: tag.value,
        };
      });
  }, [ mergeTags ]);

  function handleContentChange({ text }: { text: string }) {
    handleChange({ name: `invoice_reference`, value: text });
  }

  return (
    <div className={ `w-full` }>
      <FormLabel>
        { `Invoice Reference` }
      </FormLabel>
      <TextEditor
        value={ policyFormData.invoice_reference }
        onChange={ handleContentChange }
        mergeTags={ formattedMergeTags }
        mode={ `sms` }
        editorClassname={ `min-h-[50px]` }
      />
    </div>
  );
}
