import { IAICallsResponse } from 'shared';

import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const aiCallsApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchAICalls: builder.query<IAICallsResponse, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `/ai-calls`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: result => result ?
        [
          ...result.calls.map(({ id }) => ({ type: CacheTags.AICalls, id })),
          { type: CacheTags.AICalls, id: `LIST` },
        ]
        : [{ type: CacheTags.AICalls, id: `LIST` }],
    }),

  }),
});

export const {
  useFetchAICallsQuery,
} = aiCallsApi;
