import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export const emailTemplatesApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchEmailTemplates: builder.query({
      query: org => ({
        url: `email-templates`,
        method: `GET`,
        headers: {
          [orgHeader]: org,
        },
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (response: any) => {
        return response?.templates;
      },
      providesTags: result => result ?
        [
          ...result.map(({ id }) => ({ type: CacheTags.EmailTemplates, id })),
          { type: CacheTags.EmailTemplates, id: `LIST` },
        ]
        : [{ type: CacheTags.EmailTemplates, id: `LIST` }],
    }),
    createEmailTemplate: builder.mutation({
      query: ({ org, type }) => {
        return ({
          url: `email-templates`,
          method: `POST`,
          params: {
            type,
          },
          headers: {
            [orgHeader]: org,
          },
        });
      },
      invalidatesTags: [{ type: CacheTags.EmailTemplates, id: `LIST` }],
    }),
    updateEmailTemplate: builder.mutation({
      query: ({ org, id, ...update }) => ({
        url: `email-templates/${id}`,
        method: `PATCH`,
        headers: {
          [orgHeader]: org,
        },
        data: update,
      }),
      invalidatesTags: ({ id }) => [{ type: CacheTags.EmailTemplates, id }],
    }),
    deleteEmailTemplate: builder.mutation({
      query: ({ org, id }) => ({
        url: `email-templates/${id}`,
        method: `DELETE`,
        headers: {
          [orgHeader]: org,
        },
      }),
      invalidatesTags: () => [{ type: CacheTags.EmailTemplates, id: `LIST` }],
    }),
    duplicateEmailTemplate: builder.mutation({
      query: ({ org, id }) => ({
        url: `email-templates/duplicate/${id}`,
        method: `POST`,
        headers: {
          [orgHeader]: org,
        },
      }),
      invalidatesTags: () => [{ type: CacheTags.EmailTemplates, id: `LIST` }],
    }),
    sendTestEmail: builder.query({
      query: ({ org, templateId }) => ({
        url: `email-templates/${templateId}/test`,
        method: `GET`,
        headers: {
          [orgHeader]: org,
        },
      }),
    }),
  }),
});

export const {
  useFetchEmailTemplatesQuery,
  useCreateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useDuplicateEmailTemplateMutation,
  useLazySendTestEmailQuery,
} = emailTemplatesApi;
