import { PolicyType, PreviewLineItem, toDecimal } from "shared";
import { When } from "react-if";
import { Parser } from "html-to-react";

import { useAdhocActionModal } from "../../../hooks/useAdhocActionModal";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { InvoiceLineItems } from "../../../../common/Components/Invoice/InvoiceLineItems";
import { Card } from "../../../../common/Atoms/Card";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { Button } from "../../../../common/Atoms/Button";
import { Notification } from "../../../../common/Atoms/Notification";
import { useTrialActionLimitReached } from "../../../hooks/useTrialActionsReached";
import { CustomIframe } from "../../IFrame";
import { useIsSafeMode } from "../../../hooks/useIsSafeMode";
import { SMSPreview } from "../../Molecules/SMSPreview";

const htmlParser = Parser();

export function PreviewSection() {
  const { view, preview, previewLoading, saveAndExecute, loading, result } = useAdhocActionModal();
  const actionLimitReached = useTrialActionLimitReached();
  const safeModeOn = useIsSafeMode();

  if (view !== `preview`) return null;

  function renderContent() {
    if (!preview) return null;

    const previewType = preview.type;

    if (previewType === PolicyType.LATE_FEE) {
      const canApply = !(loading || !!result || !preview?.now?.lineItems.length || actionLimitReached);

      return (
        <div className={ `w-full` }>

          <Card >
            <div className={ `flex justify-between` }>
              <Paragraph>
                { `Invoice preview` }
              </Paragraph>
            </div>
            <InvoiceLineItems
              lineItems={ preview.now?.lineItems || [] }
              includeAccountCode
            />
          </Card>

          {
            preview?.now?.notes?.map(n => (
              <Notification
                key={ n.message }
                type={ n.type }
              >
                { n.message }
              </Notification>
            ))
          }

          <When condition={ preview?.invoice?.frozen && canApply }>
            <Notification
              type={ `info` }
            >
              { `Invoice is frozen. You can still apply this late fee.` }
            </Notification>
          </When>

          <div className={ `w-full flex justify-end mt-2` }>
            <div className={ `flex flex-col items-end` }>
              <Button
                onClick={ saveAndExecute }
                color={ `orange` }
                disabled={ !canApply }
              >
                { `Apply Now` }
              </Button>
              <When condition={ safeModeOn }>
                <Paragraph
                  variant={ `help` }
                >
                  { `This will apply even in safe mode!` }
                </Paragraph>
              </When>
            </div>
          </div>
        </div>
      );
    }

    if (previewType === PolicyType.STATEMENT_LATE_FEE) {
      // Group the line items by currency
      const groupedByCurrency: Record<string, PreviewLineItem[]> = {};

      preview.whenScheduled?.lineItems.forEach(lineItem => {
        if (!groupedByCurrency[lineItem.currency]) {
          groupedByCurrency[lineItem.currency] = [];
        }

        groupedByCurrency[lineItem.currency].push(lineItem);
      });

      return (
        <div className={ `w-full` }>
          <div className={ `space-y-1` }>
            {
              Object.keys(groupedByCurrency).map(currency => {
                const total = preview.whenScheduled?.lineItems.reduce((acc, lineItem) => {
                  if (lineItem.currency === currency) {
                    return acc + lineItem.totalCents;
                  }

                  return acc;
                }, 0);

                const totalFormatted = new Intl.NumberFormat(`en-US`, {
                  style: `currency`,
                  currency,
                }).format(toDecimal(total));

                return (
                  <Card key={ currency }>
                    <div className={ `flex justify-between` }>
                      <Paragraph>
                        { `Invoice preview for ${currency}` }
                      </Paragraph>

                      { /* Total Value */ }
                      <Paragraph>
                        <Paragraph as={ `span` }>
                          { `Total: ` }
                        </Paragraph>

                        { totalFormatted }
                      </Paragraph>
                    </div>
                    <InvoiceLineItems
                      lineItems={ groupedByCurrency[currency] }
                      includeAccountCode
                    />
                  </Card>
                );
              })
            }

            {
              preview?.whenScheduled?.notes?.map(n => (
                <Notification
                  key={ n.message }
                  type={ n.type }
                >
                  { n.message }
                </Notification>
              ))
            }
          </div>

          <div className={ `w-full flex justify-end mt-2` }>
            <div className={ `flex flex-col items-end` }>
              <Button
                onClick={ saveAndExecute }
                color={ `orange` }
                disabled={ loading || !!result || !preview?.whenScheduled?.lineItems.length || !preview?.canApply || actionLimitReached }
              >
                { `Apply Now` }
              </Button>
              <When condition={ safeModeOn }>
                <Paragraph
                  variant={ `help` }
                >
                  { `This will apply even in safe mode!` }
                </Paragraph>
              </When>
            </div>
          </div>
        </div>
      );
    }

    if (previewType === PolicyType.REMINDER) {
      if (!preview?.html) {
        return (
          <Notification
            type={ `error` }
            className={ `mb-4` }
          >
            { `No preview available` }
          </Notification>
        );
      }

      return (
        <div className={ `min-h-[100px] mt-4` }>
          <Paragraph
            className={ `pb-2 ml-1` }
          >
            { `Email Preview` }
          </Paragraph>
          <Card className={ `rounded-md shadow-xl border-gray-100 border` }>
            <CustomIframe
              style={ {
                width: `100%`,
                height: `100%`,
                minHeight: `400px`,
              } }
            >
              { htmlParser.parse(preview?.html || ``) }
            </CustomIframe>
          </Card>

          <div className={ `w-full flex justify-end mt-2` }>
            <div className={ `flex flex-col items-end` }>
              <Button
                onClick={ saveAndExecute }
                color={ `orange` }
                disabled={ loading || !!result || !preview?.canApply || actionLimitReached }
              >
                { `Send Now` }
              </Button>
              <When condition={ safeModeOn }>
                <Paragraph
                  variant={ `help` }
                >
                  { `This will apply even in safe mode!` }
                </Paragraph>
              </When>
            </div>
          </div>
        </div>
      );
    }

    if (previewType === PolicyType.SMS) {
      if (!preview?.body || !preview?.to) {
        return null;
      }

      return (
        <div>
          <SMSPreview
            body={ preview.body }
            to={ preview.to }
          />

          <div className={ `w-full flex justify-end mt-2` }>
            <div className={ `flex flex-col items-end` }>
              <Button
                onClick={ saveAndExecute }
                color={ `orange` }
                disabled={ loading || !!result || !preview?.canApply || actionLimitReached }
              >
                { `Send Now` }
              </Button>
              <When condition={ safeModeOn }>
                <Paragraph
                  variant={ `help` }
                >
                  { `This will apply even in safe mode!` }
                </Paragraph>
              </When>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <LoadingOverlay
      loading={ previewLoading }
      message={ `Generating preview...` }
    >
      <div className={ `p-2 min-h-[150px]` }>
        { renderContent() }

        <When condition={ preview?.message }>
          <Notification type={ `info` }>
            { preview?.message }
          </Notification>
        </When>
      </div>
    </LoadingOverlay>
  );
}
