import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PolicyType, langauge } from "shared";

import { useCurrentGroup } from "../../../../hooks/useGroups";
import { usePolicies } from "../../../../hooks/usePolicies";
import { useCreatePolicyMutation } from "../../../../services/api/policyApi/policy";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { useAllowedTypes } from "../../../../hooks/useAllowedActionTypes";
import { open } from "../../../../slices/policyDrawer";
import { classNames } from "../../../../../common/lib/classNames";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { Divider } from "../../../../../common/Atoms/Divider";

const descriptionMap: {
  [key in PolicyType]: string;
} = {
  [PolicyType.LATE_FEE]: `Charge late fees and interest per invoice`,
  [PolicyType.STATEMENT_LATE_FEE]: `Charge & group late fees and interest per contact on a recurring schedule`,
  [PolicyType.DISCOUNT]: `Automatically apply prompt payment discounts to invoices`,
  [PolicyType.REMINDER]: `Send emails for invoices based on when its due, issued or marked paid`,
  [PolicyType.SMS]: `Send SMS alerts for invoices based on when its due or issued`,
  [PolicyType.STATEMENT]: `Schedule recurring automatic statement sends`,
  [PolicyType.ESCALATION]: `Create manual tasks when invoices are unpaid to call, stop credit or send letters`,
  [PolicyType.AI_CALL]: `Automatically call customers to follow up on invoices`,
  [PolicyType.QUOTE_EMAIL]: `Send reminder emails and follow ups for quotes`,
};

const order = [
  PolicyType.LATE_FEE,
  PolicyType.STATEMENT_LATE_FEE,
  PolicyType.REMINDER,
  PolicyType.STATEMENT,
  PolicyType.ESCALATION,
  PolicyType.QUOTE_EMAIL,
  PolicyType.SMS,
  // PolicyType.AI_CALL,
  PolicyType.DISCOUNT,
];

const colMap: { [key: number]: string } = {
  1: `grid-cols-1`,
  2: `grid-cols-2`,
};

export function CreateNewPolicy() {
  const [createPolicy, { isLoading: policyCreateLoading }] = useCreatePolicyMutation();
  const { data: policies, isLoading: policiesLoading } = usePolicies();
  const { policies: groupPolicies, groupFormData } = useCurrentGroup();

  const [createdPolicyId, setCreatedPolicyId] = useState<number | null>(null);
  const currentOrg = useGetSelectedOrganisation();
  const dispatch = useDispatch();
  const { policyTypes } = useAllowedTypes();

  const policyButtons = order
  // Temp feature flag
    .filter(type => policyTypes.includes(type))
    // Filter incorrect resource types
    // .filter(type => {
    //   if (resourceType === `invoice`) {
    //     return invoiceTypes.includes(type);
    //   }

  //   if (resourceType === `quote`) {
  //     return quoteTypes.includes(type);
  //   }

  //   if (resourceType === `contact`) {
  //     return statementTypes.includes(type);
  //   }

    //   return false;
    // })
    .map(type => {
      const button = {
        name: langauge.policyType[type],
        description: descriptionMap[type],
        type,
        disabled: false,
        tooltip: ``,
        className: ``,
        earlyAccessBadge: ``,
        new: false,
      };

      if (type === PolicyType.DISCOUNT && groupPolicies.some(p => p.policy_type === PolicyType.DISCOUNT)) {
      // Only allow one discount
        button.disabled = true;
        button.tooltip = `Only one discount per group is allowed`;
      }

      if (type === PolicyType.AI_CALL) {
        button.earlyAccessBadge = `You have early access to this feature. It may not be perfect so please let us know if you have any feedback!`;
      }

      if (type === PolicyType.QUOTE_EMAIL) {
        button.new = true;
      }

      return button;
    })
  ;

  async function newPolicy(type: PolicyType) {
    const res = await createPolicy({ type, organisationId: currentOrg.id, groupId: groupFormData.id }).unwrap();

    const policy = res?.policy;
    if (policy) {
      setCreatedPolicyId(policy.id);
    }
  }

  // When created policy Id exists, and the policy array includes it, open the drawer
  useEffect(() => {
    if (createdPolicyId && policies?.some(p => p.policy.id === createdPolicyId)) {
      const policy = policies.find(p => p.policy.id === createdPolicyId);
      dispatch(open(policy));

      setCreatedPolicyId(null);
    }
  }, [createdPolicyId, policies]);

  return (
    <div className={ `0 p-4 rounded-md mb-4 bg-white` }>
      <Heading>
        { `Create a new policy` }
      </Heading>
      <Paragraph
        variant={ `help` }
      >
        { `Policies are automation rules that specify when and what Paidnice should do for you. ` }
      </Paragraph>

      <Divider
        weight={ `light` }
        spacing={ `small` }
      />
      { /* Render the list of buttons */ }
      <div className={ classNames(
        `grid gap-3 `,
        colMap[policyButtons.length] || `grid-cols-4`,
      ) }>
        {
          policyButtons.map(button => (
            <button
              className={ classNames(
                `text-left rounded-md`,
                `hover:bg-gray-50`,
                button.className,
              ) }
              key={ button.type }
              onClick={ () => newPolicy(button.type) }
              disabled={ button.disabled || policiesLoading || policyCreateLoading }

            >
              <div className={ `h-full p-2` }>
                <Heading
                  variant={ `secondary` }
                  className={ `mb-1 text-indigo-600` }
                >
                  <span>{ button.name }{ ` ` }</span>

                  <span
                    aria-hidden={ `true` }
                  >
                    &rarr;
                  </span>
                </Heading>

                <Paragraph
                  variant={ `help` }
                >
                  { button.description }
                </Paragraph>
              </div>
            </button>
          ))
        }
      </div>
    </div>
  );
}
