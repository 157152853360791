import { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {  When } from 'react-if';
import { toFormattedString } from 'shared';

import { classNames }  from '../lib/classNames';

import { Paragraph }  from './Typography/Paragraph';
import { Select } from './Select';

interface IPaginationProps {
  limit: number
  total: number
  activePage: number
  onChangePage: (page: number) => void
  count: number
  subMessage?: string
  className?: string
  onPerPageChange?: (limit: number) => void
  perPageOptions?: number[]
  onExport?: () => void
  noBorder?: boolean
}

export function Pagination({
  limit,
  total,
  activePage,
  onChangePage,
  count,
  subMessage,
  className,
  onPerPageChange,
  perPageOptions,
  onExport,
  noBorder,
}: IPaginationProps) {

  const showPerPage = (!!perPageOptions && !!onPerPageChange);

  function onClick(evt, type: `previous` | `next` | number, disabled?: boolean) {
    evt.preventDefault();

    if (disabled) {
      return;
    }

    let nextPage = 0;

    switch (type) {
    case `previous`:
      if (activePage > 1) {
        nextPage = activePage - 1;
      }
      else {
        nextPage = activePage;
      }
      break;
    case `next`:
      if (activePage < totalPages) {
        nextPage = activePage + 1;
      }
      else {
        nextPage = activePage;
      }
      break;
    default:
      nextPage = type;
    }

    if (nextPage !== activePage) {
      onChangePage(nextPage);
    }
  }

  const totalPages = useMemo(() => {
    return Math.ceil((total / limit));
  }, [total, limit]);

  return (
    <div className={ classNames(
      `flex items-center justify-between bg-white`,
      noBorder ? `` : `border-t border-gray-200`,
      className,
    ) }>
      <div className={ `flex flex-1 justify-between sm:hidden` }>
        <a
          onClick={ e => onClick(e, `previous`, activePage === 1) }
          href={ `#` }
          className={ `relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50` }
        >
          { `Previous` }
        </a>
        <a
          onClick={ e => onClick(e, `next`, activePage === totalPages) }
          href={ `#` }
          className={ `relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50` }
        >
          { `Next` }
        </a>
      </div>
      <div className={ `hidden sm:flex sm:flex-1 sm:items-center sm:justify-between` }>
        <div>
          <When condition={ total }>
            <Paragraph variant={ `secondary` }>
              { `Showing ` }
              <Paragraph as={ `span` }>
                { toFormattedString((activePage - 1) * limit + 1) }
              </Paragraph>
              { ` to ` }
              <Paragraph as={ `span` }>
                { toFormattedString(((activePage - 1) * limit ) + count) }
              </Paragraph>
              { ` of` }{ ` ` }
              <Paragraph as={ `span` }>
                { toFormattedString(total) }
              </Paragraph>
              { ` results` }
            </Paragraph>
          </When>
          <When condition={ subMessage }>
            <Paragraph variant={ `help` }>
              { subMessage }
            </Paragraph>
          </When>
          <When condition={ !!onExport }>
            <Paragraph variant={ `help` }>
              <a href={ `#` }
                className={ `block` }
                onClick={ onExport }>
                { `Export this page` }
              </a>
            </Paragraph>
          </When>
        </div>

        <div className={ `flex` }>
          <div>
            <nav className={ `isolate inline-flex -space-x-px rounded-md shadow-sm` }
              aria-label={ `Pagination` }>
              <a
                onClick={ e => onClick(e, `previous`, activePage === 1) }
                href={ `#` }
                className={ `relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20` }
              >
                <span className={ `sr-only` }>{ `Previous` }</span>
                <ChevronLeftIcon className={ `h-5 w-5` }
                  aria-hidden={ `true` } />
              </a>
              {
                Array.from(Array(totalPages).keys()).map(key => {
                  key = key + 1;

                  const selected = key === activePage;

                  if (totalPages > 7 && !selected) {
                    if (key > 3 && key < (totalPages - 1)) {
                      if (key === 4 && activePage < 4) {
                        return (
                          <span
                            key={ key }
                            className={ `relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700` }
                          >
                            { `...` }
                          </span>
                        );
                      }

                      return null;
                    }
                  }

                  return (
                    <a
                      onClick={ e => onClick(e, key) }
                      key={ key }
                      href={ `#` }
                      aria-current={ selected ? `page` : null }
                      className={
                        classNames(
                          `relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`,
                          selected && `z-10 border-lateGreen-500 bg-lateGreen-50 font-medium text-lateGreen-600`,
                        )
                      }
                    >
                      { key }
                    </a>
                  );
                })
              }
              <a
                onClick={ e => onClick(e, `next`, activePage === totalPages) }
                href={ `#` }
                className={ `relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20` }
              >
                <span className={ `sr-only` }>{ `Next` }</span>
                <ChevronRightIcon className={ `h-5 w-5` }
                  aria-hidden={ `true` } />
              </a>
            </nav>
          </div>
          <When condition={ showPerPage }>
            <div className={ `ml-2` }>
              <Select
                displaySuffix={ ` per page` }
                className={ `mt-0` }
                // label={ `Per page` }
                options={ perPageOptions?.map(option => ({
                  label: option.toString(),
                  value: option,
                })) }
                selected={ limit }
                onChange={ e => onPerPageChange(e.value) }
              />
            </div>
          </When>
        </div>
      </div>
    </div>
  );
}
