import React from 'react';

import { classNames }  from '../lib/classNames';

const spanClass = {
  1: `sm:col-span-1`,
  2: `sm:col-span-2`,
  3: `sm:col-span-3`,
  4: `sm:col-span-4`,
  5: `sm:col-span-5`,
  6: `sm:col-span-6`,
  7: `sm:col-span-7`,
  8: `sm:col-span-8`,
  9: `sm:col-span-9`,
  10: `sm:col-span-10`,
  11: `sm:col-span-11`,
  12: `sm:col-span-12`,
};

interface IGridItemProps {
  children: React.ReactNode
  span?: number
  position?: `bottom` | `top`
  className?: string
}

export function GridItem({ children, span = 1, position, className }: IGridItemProps) {
  return (
    <div
      className={
        classNames(
          spanClass[span],
          className,
          position === `bottom` && `flex items-end`,
          position === `top` && `flex items-start`,
        )
      }
    >
      { children }
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ForwardRefGridItem = React.forwardRef<any, any>(({ children, span = 1, className }: IGridItemProps, ref) => {
  return (
    <div
      className={
        classNames(
          spanClass[span],
          className,
        )
      }
      ref={ ref }
    >
      { children }
    </div>
  );
} );

ForwardRefGridItem.displayName = `GridItem`;
