import { EmailTemplateTypes, IInvalidField } from "shared";
import { useMemo } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { When } from "react-if";

import { useEmailTemplates } from "../../../../../hooks/useEmailTemplates";
import { Select } from "../../../../../../common/Atoms/Select";
import { Paragraph } from "../../../../../../common/Atoms/Typography/Paragraph";
import { WrappedLink } from "../../../../WrappedLink";

interface EmailTemplateSelectorProps {
  type: EmailTemplateTypes
  handleChange: ({ value, name }: { value: string; name: string; }) => void
  value: string | null
  invalid?: boolean | IInvalidField
  name: string
  nullable?: boolean
  label?: string
  description?: string
  emptyText?: string
  helpIcon?: string
  tourTarget?: string
  currentPolicyId: number;
}

export function EmailTemplateSelector({
  type,
  handleChange,
  value,
  invalid,
  name,
  nullable,
  label,
  description,
  emptyText,
  helpIcon,
  tourTarget,
  currentPolicyId,
}: EmailTemplateSelectorProps) {
  const { data: emailTemplates, isSuccess, isLoading } = useEmailTemplates();

  const filteredEmailTemplates = useMemo(() => {
    if (isSuccess) {
      // If late fee, include legacy
      const filterFn = type === EmailTemplateTypes.LATE_FEE ? t => (
        (t.type === type || t.type === EmailTemplateTypes.LEGACY_LATE_FEE) &&
        t.version > 0 // Ensures the user has saved it at least once
      ) : t => (
        (t.type === type) &&
        t.version > 0 // Ensures the user has saved it at least once
      );

      const templates = emailTemplates
        .filter(filterFn)
        .map(t => {
          const otherPolicies = t.policies.filter(p => p.id !== currentPolicyId);
          const hasOtherPolicies = otherPolicies.length > 0;
          const label = (
            <span className={ `flex` }>
              { t.name }
              <WrappedLink
                to={ `/settings/email-templates/${t.id}` }
                target={ `_blank` }
              >
                <Paragraph
                  className={ `flex ml-1` }
                  variant={ `link` }>
                  { `Edit` }
                  <ArrowTopRightOnSquareIcon className={ `w-4 h-4 ml-1` } />
                </Paragraph>
              </WrappedLink>

              <When condition={ hasOtherPolicies }>
                <Paragraph
                  className={ `ml-1 text-xs text-gray-500 self-center` }
                >
                  { `(used in ${otherPolicies.length} other ${otherPolicies.length === 1 ? `policy`: `policies`})` }
                </Paragraph>
              </When>
            </span>
          );

          return {
            label,
            value: t.id,
          };
        });

      return templates;
    }

    return [];
  }, [emailTemplates, isSuccess, type]);

  const emptyTextChecked = (emptyText && filteredEmailTemplates.length === 0) ? `No templates for this type exist` : emptyText;

  return (
    <Select
      options={ filteredEmailTemplates }
      selected={ value }
      name={ name }
      onChange={ e => handleChange(e) }
      invalid={ invalid }
      label={ label }
      description={ description }
      nullable={ !!nullable }
      emptyText={ emptyTextChecked }
      helpIcon={ helpIcon }
      tourTarget={ tourTarget }
      loading={ isLoading }
    />
  );
}
