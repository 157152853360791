import { Fragment, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router';

import { useGetSelectedOrganisation } from '../../../hooks/useGetSelectedOrganisation';
import { classNames } from '../../../../common/lib/classNames';
import { env } from '../../../../common/lib/env';

import { IContactTableRow } from './ContactTable';

interface ContactRowActionsProps {
  contactRow: IContactTableRow;
}

export function ContactRowActions({ contactRow }: ContactRowActionsProps) {
  const navigate = useNavigate();
  const currentOrg = useGetSelectedOrganisation();

  // TODO: QBO link
  const showLink = currentOrg.type === `XERO`;

  const options = useMemo(() => {
    const opts = [{
      title: `View Portal`,
      action: handlePortalView,
      icon: ArrowTopRightOnSquareIcon,
      tooltip: `View this contact in the customer facing portal`,
    }];

    if (showLink) {
      opts.push({
        title: `View In Xero`,
        action: handleViewXero,
        icon: ArrowTopRightOnSquareIcon,
        tooltip: `View this contact in Xero`,
      });
    }

    return opts;
  }, [ contactRow, currentOrg ]);

  function handleViewXero() {
    // No link, build one
    const l = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${currentOrg.shortcode}&redirecturl=/Contacts/View/${contactRow.externalId}`;

    window.open(l, `_blank`);

    return;
  }

  function handleOptionClick(e, item) {
    e.preventDefault();
    e.stopPropagation();

    item.action();
  }

  function onViewContact(e) {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/${currentOrg?.id}/contacts/${contactRow.id}`);
  }

  function handlePortalView() {
    window.open(`${env.portalWebUrl}/c/${contactRow.hash}`, `_blank`);
  }

  return (
    <>
      <div className={ `inline-flex rounded-md shadow-sm` }>
        <Tooltip title={ `View more details & history` }>
          <span>
            <button
              type={ `button` }
              onClick={ e => onViewContact(e) }
              className={ `relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-lateGreen-500 focus:outline-none focus:ring-1 focus:ring-lateGreen-500` }
            >
              { `View Contact` }
            </button>
          </span>
        </Tooltip>
        <Menu as={ `div` }
          className={ `relative -ml-px block` }>
          <MenuButton
            onClick={ e => e.stopPropagation() }
            className={ `relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-lateGreen-500 focus:outline-none focus:ring-1 focus:ring-lateGreen-500` }
          >
            <span className={ `sr-only` }>{ `Open options` }</span>
            <ChevronDownIcon className={ `h-5 w-5` }
              aria-hidden={ `true` } />
          </MenuButton>
          <Transition
            as={ Fragment }
            enter={ `transition ease-out duration-100` }
            enterFrom={ `transform opacity-0 scale-95` }
            enterTo={ `transform opacity-100 scale-100` }
            leave={ `transition ease-in duration-75` }
            leaveFrom={ `transform opacity-100 scale-100` }
            leaveTo={ `transform opacity-0 scale-95` }
          >
            <MenuItems className={ `absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none` }>
              <div className={ `py-1` }>
                { options.map(item => (
                  <MenuItem key={ item.title }>
                    { ({ focus }) => (
                      <Tooltip title={ item.tooltip }
                        placement={ `left` }>
                        <span>
                          <a
                            href={ `#` }
                            onClick={ e => handleOptionClick(e, item) }
                            className={ classNames(
                              focus ? `bg-gray-100 text-gray-900` : `text-gray-700`,
                              `block px-4 py-2 text-sm`,
                            ) }
                          >
                            <div className={ `flex justify-between` }>
                              <item.icon className={ `h-5 w-5` }
                                aria-hidden={ `true` } />
                              { item.title }
                            </div>
                          </a>
                        </span>
                      </Tooltip>
                    ) }
                  </MenuItem>
                )) }
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </>
  );
}
