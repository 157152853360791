import { useCallback, useMemo, useReducer, useState } from "react";
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { IQuoteResponseItem, QuoteStatus, langauge, quoteEventLanguageMap, viewableQuoteStatuses } from "shared";
import { DocumentArrowUpIcon, DocumentCheckIcon, DocumentIcon, DocumentMinusIcon, FireIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { When } from "react-if";

import { useQuotes } from "../../hooks/useQuotes";
import { DatePopover } from "../../../common/Components/DatePopover";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { WrappedLink } from "../WrappedLink";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Card } from "../../../common/Atoms/Card";
import { useAccountingSystemTerm } from "../../hooks/useAccountingSystemTerm";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";
import { CardContent } from "../../../common/Atoms/CardContent";
import { env } from "../../../common/lib/env";
import { ITimeLineFeedItem, TimelineFeed } from "../../../common/Atoms/TimelineFeed";
import { useSyncQuotesMutation } from "../../services/api/quotesApi/quotes";
import { ITab, Tabs } from "../../../common/Atoms/Tabs";
import { getQuoteIcon } from "../../../common/lib/helpers";
import { useApiRequest } from "../../hooks/useApiRequest";

function loadingReducer(state: Record<string, boolean>, action: Record<string, boolean>) {
  return {
    ...state,
    ...action,
  };
}

export function QuotesRoot() {
  const { quotes, isLoading } = useQuotes();
  const currentOrg = useGetSelectedOrganisation();
  const quoteTerm = useAccountingSystemTerm(`Quotes`);
  const [syncQuotes, { isLoading: syncQuotesLoading }] = useSyncQuotesMutation();
  const request = useApiRequest();
  const [currentTab, setCurrentTab] = useState<QuoteStatus>(QuoteStatus.SENT);
  const [downloadingPOs, setDownloadingPOs] = useReducer(loadingReducer, {});

  const quotesLastSyncedText = currentOrg?.quotesLastSyncedAgo !== undefined ? `Last synced ${currentOrg.quotesLastSyncedAgo} minute${currentOrg.quotesLastSyncedAgo === 1 ? `` : `s`} ago` : ``;

  function onPortalView(hash: string) {
    window.open(`${env.portalWebUrl}/q/${hash}`, `_blank`, `noopener noreferrer`);
  }

  function handleViewXero(quoteExternalId: string) {
    // Not a proper deep link as not yet supported in Xero
    const l = `https://go.xero.com/app/${currentOrg.shortcode}/quotes/view/${quoteExternalId}`;

    window.open(l, `_blank`, `noopener noreferrer`);
  }

  function handleQuoteSync() {
    if (currentOrg?.id) {
      syncQuotes({ organisationId: currentOrg?.id });
    }
  }

  async function onDownloadPO(quote: IQuoteResponseItem) {

    setDownloadingPOs({
      [quote.id]: true,
    });

    try {
      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: `${env.baseApiUrl}quotes/${quote.id}/download-po`,
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement(`a`);
      link.href = url;
      link.setAttribute(`download`, quote.customerDataPOFilename || `quote-PO-${quote.number}` );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (e) {
      const responseObj = await e.response?.data?.text();
      toast.error(`${responseObj ? `${responseObj}` : `Could not download P.O.`}`);
    }

    setDownloadingPOs({
      [quote.id]: false,
    });

  }

  const getDetailPanelContent: (params: GridRowParams<IQuoteResponseItem>) => React.ReactNode = useCallback(params => {

    const feed: ITimeLineFeedItem[] = params.row.events?.map(e => ({
      id: e.id,
      description: quoteEventLanguageMap.timeline[e.type].description,
      timestamp: e.createdAt.toString(),
      icon: getQuoteIcon(e.type),
    })) || [];

    return (
      <div className={ `p-6` }>
        <TimelineFeed
          feed={ feed }
          timezone={ currentOrg?.validatedTimezone }
        />
      </div>
    );
  }, []);

  const columns: GridColDef<IQuoteResponseItem>[] = useMemo(() => {
    const cols: GridColDef<IQuoteResponseItem>[] = [
      {
        field: `issueDateString`,
        headerName: `Issue Date`,
        flex: 1,
        sortComparator: (v1, v2) => {
          return new Date(v1).getTime() - new Date(v2).getTime();
        },
        renderCell: ({ row }) => {
          return <DatePopover
            date={ row.issueDateString }
            labelFormat={ `dd LLL yyyy` }
            organisationTimezone={ currentOrg?.validatedTimezone }
          />;
        },
      },
      {
        field: `number`,
        headerName: `Number`,
        flex: 1,
      },
      {
        field: `expiryDateString`,
        headerName: `Expiry Date`,
        flex: 1,
        sortComparator: (v1, v2) => {
          // No expiry for both, dont sort
          if (!v1 && !v2) return 0;

          if (!v1) return 1;

          if (!v2) return -1;

          return new Date(v1).getTime() - new Date(v2).getTime();
        },
        renderCell: ({ row }) => {
          if (!row.expiryDateString) return `-`;

          return <DatePopover
            date={ row.expiryDateString }
            labelFormat={ `dd LLL yyyy` }
            organisationTimezone={ currentOrg?.validatedTimezone }
          />;
        },
      },
      {
        field: `contactName`,
        headerName: `Contact`,
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <WrappedLink
              to={ `/contacts/${ row.contactId }` }
            >
              <Paragraph
                variant={ `link` }
                as={ `span` }
              >
                { row.contactName }
              </Paragraph>
            </WrappedLink>
          );
        },
      },
      {
        field: `totalAmountFormatted`,
        headerName: `Value`,
        flex: 1,
        valueGetter: (value, row)  => row.amountTotalCents,
        valueFormatter: (value, row) => row.totalAmountFormatted,
      },
      {
        field: `customerDataPONumber`,
        headerName: `P.O. No.`,
        flex: 1,
      },
      {
        field: `customerDataPOUploadUrl`,
        headerName: `P.O. Document`,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          if (!row.customerDataPOUploadUrl) return ``;

          return (
            <Tooltip title={ row.customerDataPOFilename }>
              <span className={ `flex justify-center items-center h-full` }>
                <Paragraph
                  variant={ `link` }
                  className={ `truncate` }
                >
                  <a
                    onClick={ e => {
                      if (downloadingPOs[row.id]) return;

                      e.preventDefault();
                      onDownloadPO(row);
                    } }
                  >
                    <DocumentArrowDownIcon className={ `h-5 w-5 text-blue-400 inline-block` } />
                    <When condition={ downloadingPOs[row.id] }>
                      { `Downloading...` }
                    </When>

                    <When condition={ !downloadingPOs[row.id] }>
                      { row.customerDataPOFilename ? row.customerDataPOFilename : `Download` }
                    </When>
                  </a>
                </Paragraph>
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: `actions`,
        type: `actions`,
        getActions: ({ row }) => {
          const items = [
            <GridActionsCellItem
              key={ `${row.id}-portal-link` }
              onClick={ () => onPortalView(row.hash) }
              // icon={ null }
              label={ `View Customer Portal` }
              disabled={ !viewableQuoteStatuses.includes(row.status) }
              showInMenu
            />,
          ];

          if (currentOrg?.accountingSystemType === `XERO`) {
            // Add deep link
            items.push(
              <GridActionsCellItem
                key={ `${row.id}-xero-link` }
                onClick={ () => handleViewXero(row.externalId) }
                // icon={ null }
                label={ `View in Xero` }
                showInMenu
              />,
            );
          }

          return items;
        },
      },
    ];

    return cols;
  }, [quotes, currentOrg, downloadingPOs]);

  const tabs: ITab[] = useMemo(() => {
    return [
      {
        name: langauge.quotes.status[QuoteStatus.SENT],
        onClick: () => setCurrentTab(QuoteStatus.SENT),
        icon: DocumentArrowUpIcon,
        href: `#`,
        current: currentTab === QuoteStatus.SENT,
        badge:  quotes[QuoteStatus.SENT] && {
          text: `${quotes[QuoteStatus.SENT]?.length}`,
          color: `gray`,
        },
      },
      {
        name: langauge.quotes.status[QuoteStatus.ACCEPTED],
        onClick: () => setCurrentTab(QuoteStatus.ACCEPTED),
        icon: DocumentCheckIcon,
        href: `#`,
        current: currentTab === QuoteStatus.ACCEPTED,
        badge:  quotes[QuoteStatus.ACCEPTED] && {
          text: `${quotes[QuoteStatus.ACCEPTED]?.length}`,
          color: `gray`,
        },
      },
      {
        name: langauge.quotes.status[QuoteStatus.DECLINED],
        onClick: () => setCurrentTab(QuoteStatus.DECLINED),
        icon: DocumentMinusIcon,
        href: `#`,
        current: currentTab === QuoteStatus.DECLINED,
        badge:  quotes[QuoteStatus.DECLINED] && {
          text: `${quotes[QuoteStatus.DECLINED]?.length}`,
          color: `gray`,
        },
      },
      {
        name: langauge.quotes.status[QuoteStatus.DRAFT],
        onClick: () => setCurrentTab(QuoteStatus.DRAFT),
        icon: DocumentIcon,
        href: `#`,
        current: currentTab === QuoteStatus.DRAFT,
        badge:  quotes[QuoteStatus.DRAFT] && {
          text: `${quotes[QuoteStatus.DRAFT]?.length}`,
          color: `gray`,
        },
      },
      {
        name: langauge.quotes.status[QuoteStatus.CONVERTED],
        onClick: () => setCurrentTab(QuoteStatus.CONVERTED),
        icon: FireIcon,
        href: `#`,
        current: currentTab === QuoteStatus.CONVERTED,
        badge:  quotes[QuoteStatus.CONVERTED] && {
          text: `${quotes[QuoteStatus.CONVERTED]?.length}`,
          color: `gray`,
        },
      },
      {
        name: langauge.quotes.status[QuoteStatus.DELETED],
        onClick: () => setCurrentTab(QuoteStatus.DELETED),
        icon: TrashIcon,
        href: `#`,
        current: currentTab === QuoteStatus.DELETED,
        badge:  quotes[QuoteStatus.DELETED] && {
          text: `${quotes[QuoteStatus.DELETED]?.length}`,
          color: `gray`,
        },
      },
    ];
  }, [quotes, setCurrentTab, currentTab]);

  return (
    <Card>
      <MultiButtonCardHeader
        title={ quoteTerm }
        noDivider
        secondaryDescription={ quotesLastSyncedText }
        buttons={ [{
          buttonText: `Sync Now`,
          onClick: handleQuoteSync,
          loading: syncQuotesLoading,
          disabled: syncQuotesLoading,
        }] }
      />
      <CardContent>
        <Tabs
          tabs={ tabs }
        />
        <div className={ `h-[650px]` }>
          <DataGridPro
            rows={ quotes[currentTab] || [] }
            columns={ columns }
            loading={ isLoading }
            getDetailPanelContent={ getDetailPanelContent }
            getDetailPanelHeight={ () => `auto` }
          />
        </div>
      </CardContent>
    </Card>
  );
}
