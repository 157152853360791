import { IEscalationParams, IEscalationResponse } from 'shared';

import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';
import { CacheTags } from '../../types/cacheTags';

export const escalationApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchEscalations: builder.query<IEscalationResponse, IEscalationParams>({
      query: ({ organisationId, ...params }) => ({
        url: `escalations`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
        params: {
          ...params,
        },
      }),
      providesTags: result => result ?
        [
          ...result.escalations.map(({ id }) => ({ type: CacheTags.Escalations, id })),
          { type: CacheTags.Escalations, id: `LIST` },
        ]
        : [{ type: CacheTags.Escalations, id: `LIST` }],
    }),
    addNote: builder.mutation({
      query: ({
        organisationId,
        escalationId,
        note,
        expectedPaymentDate,
        outcome,
        mentionedUsers,
      }) => ({
        url: `escalations/${escalationId}/notes`,
        method: `POST`,
        data: {
          note,
          expectedPaymentDate,
          outcome,
          mentionedUsers,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Escalations, id: `LIST` }],
    }),
    markCompleteEscalation: builder.mutation({
      query: ({
        organisationId,
        escalationId,
      }) => ({
        url: `escalations/${escalationId}/complete`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Escalations, id: `LIST` }],
    }),
    markSnoozeEscalation: builder.mutation({
      query: ({
        organisationId,
        escalationId,
        snoozeDays,
      }) => ({
        url: `escalations/${escalationId}/snooze/${snoozeDays}`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Escalations, id: `LIST` }],
    }),
    markCancelledEscalation: builder.mutation({
      query: ({
        organisationId,
        escalationId,
      }) => ({
        url: `escalations/${escalationId}/cancel`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Escalations, id: `LIST` }],
    }),
    reopenEscalation: builder.mutation({
      query: ({
        organisationId,
        escalationId,
      }) => ({
        url: `escalations/${escalationId}/reopen`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Escalations, id: `LIST` }],
    }),
    assignEscalation: builder.mutation({
      query: ({
        organisationId,
        escalationId,
        userId,
      }) => ({
        url: `escalations/${escalationId}/assign/${userId}`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Escalations, id: `LIST` }],
    }),
    clearAssignedEscalation: builder.mutation({
      query: ({
        organisationId,
        escalationId,
      }) => ({
        url: `escalations/${escalationId}/assign`,
        method: `DELETE`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Escalations, id: `LIST` }],
    }),
  }),
});

export const {
  useFetchEscalationsQuery,
  useAddNoteMutation,
  useMarkCompleteEscalationMutation,
  useMarkSnoozeEscalationMutation,
  useMarkCancelledEscalationMutation,
  useReopenEscalationMutation,
  useAssignEscalationMutation,
  useClearAssignedEscalationMutation,
} = escalationApi;
