import {  policyConfig } from "shared";
import { countries } from "country-codes-flags-phone-codes";

import { fieldIsValid } from "../../../../../lib/helper";
import { IOption, Select } from "../../../../../../common/Atoms/Select";

import { IFieldProps } from "./fieldProps";

const config = policyConfig[`sms_default_country_code`];

const options: IOption[] = countries
  .sort((a, b) => a.dialCode.localeCompare(b.dialCode))
  .map(c => ({
    value: c.dialCode,
    label: `${c.flag} ${c.dialCode} (${c.code})`,
    tooltip: c.name,
  }));

export function SMSDefaultCountryCode({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {

  return (
    <Select
      selected={ policyFormData.sms_default_country_code }
      onChange={ handleChange }
      label={ config.label }
      description={ `If the contact does not have a country code already, then use this one.` }
      name={ `sms_default_country_code` }
      className={ `max-w-[150px]` }
      options={ options }
      invalid={ fieldIsValid(`sms_default_country_code`, invalids) }
      nullable
      emptyText={ `Select Country Code` }
    />
  );
}
