import { useLocation } from 'react-router';
// import posthog from 'posthog-js';
// import { useSessionStorage } from 'usehooks-ts';

import { useFetchOrganisationsQuery } from '../services/api/organisationApi/organisation';
import { env } from '../../common/lib/env';

import { useUser } from './useUser';

export function useOrganisations() {
  const location = useLocation();
  const { data: user } = useUser();
  // const [posthogGroupIdentified, setPosthogGroupIdentified] = useSessionStorage(`posthogGroupIdentified`, 0);

  const isOnboarding = location.pathname.includes(`onboarding`);

  const result = useFetchOrganisationsQuery(null, {
    // Dont poll in onboarding
    pollingInterval: isOnboarding ? undefined : 1000 * 60,
    skipPollingIfUnfocused: true,
  });

  if (!result.data) {
    result.data = [];
  }

  // filter disconnected orgs when not admin
  if (!user?.adminOverride && !env.isDev) {
    result.data = result.data.filter(org => org.connected);
  }

  // if (result.data.length > 0 && (!posthogGroupIdentified || posthogGroupIdentified !== result.data.length)) {
  //   // Identify user against orgs
  //   if (posthog && env.isProd && user.id) {
  //     result.data.forEach(org => {
  //       posthog.group(`orgId`, org.id);
  //     });

  //     setPosthogGroupIdentified(result.data.length);
  //   }
  // }

  return result;
}
