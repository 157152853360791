// Initially for a "draft" actions to do today

import {  useMemo } from "react";
import { ActionStatus, ActionType, getOrdinalSuffix, IActionResponseItem, langauge, PolicyIntervalType, policyWeeklyDayIntervalMap } from "shared";
import { When } from "react-if";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";
import { ClockIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { useActionTableModels, useDashboardActions } from "../../../hooks/useActions";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { open } from "../../../slices/actionDrawer";
import { Divider } from "../../../../common/Atoms/Divider";
import { CardContent } from "../../../../common/Atoms/CardContent";
import { renderDayIndex } from "../../../lib/helper";

const allowedRetrospective = [
  ActionType.ESCALATION,
  ActionType.LATE_FEE,
];

function getHoursRemaining(date: Date, tz: string) {
  const now = new Date();
  const endOfDayOrg = DateTime.fromJSDate(date).setZone(tz).endOf(`day`).toJSDate();

  if (now > endOfDayOrg) {
    return `0`;
  }

  const diff = endOfDayOrg.getTime() - now.getTime();

  return Math.ceil(diff / (1000 * 60 * 60));
}

type GroupedAction = {
  [key in ActionType]?: IActionResponseItem[];
}

function getPolicyInfo(action: IActionResponseItem) {
  const policy = action.policies?.[0];

  if (!policy) {
    return `-`;
  }

  const { day, verb }= renderDayIndex(policy);
  const target = langauge.policy.targetStatus[policy.target_status] || `due`;
  const statementDescription = policy.interval_type === PolicyIntervalType.MONTHLY
    ? `${policy.day_index}${getOrdinalSuffix(policy.day_index)} of the month`
    : `Every ${policyWeeklyDayIntervalMap[policy.day_index]}`;

  switch (action.type) {
  case ActionType.ESCALATION:
    return `over ${policy.day_index} day${policy.day_index === 1 ? `` : `s`} due`;
  case ActionType.LATE_FEE:
    return `over ` + action.policies[0]?.grace_period + ` days due`;
  case ActionType.REMINDER:
  case ActionType.SEND_SMS:
  case ActionType.QUOTE_EMAIL:
    return `${day} day${day === `1` ? `` : `s`} ${verb} ${target}`;
  case ActionType.SEND_STATEMENT:
  case ActionType.ISSUE_STATEMENT_LATE_FEE:
    return statementDescription;

  default:
    return action.sourcePolicies;
  }
}

export function ActionableTasks() {
  const currentOrg = useGetSelectedOrganisation();
  const dispatch = useDispatch();
  const { setCurrentTab, setFilterModel } = useActionTableModels();
  const navigate = useNavigate();

  const { data: actionsRes, isLoading } = useDashboardActions();

  const actions: GroupedAction = useMemo(() => {
    const result: GroupedAction = {};
    if (!actionsRes || !actionsRes.actions) return result;

    actionsRes.actions.forEach(action => {
      if (!result[action.type]) {
        result[action.type] = [];
      }

      result[action.type]?.push(action);
    });

    return result;
  }, [actionsRes]);

  function onActionClick(actionId: string) {
    dispatch(open(actionId));
  }

  function goToActions(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    setCurrentTab(ActionStatus.APPROVAL_PENDING);

    const endOfDayOrg = DateTime.now().setZone(currentOrg?.validatedTimezone).endOf(`day`).toISO();

    // Keep it for today
    setFilterModel({
      items: [
        {
          field: `actionOn`,
          operator: `onOrBefore`,
          value: endOfDayOrg,
          id: Math.round(Math.random() * 1e5),
        },
      ],
    });

    navigate(`/${currentOrg?.id}/actions?persist=true`);
  }

  const hasActions = Object.keys(actions).length > 0;
  const notShownAmount = actionsRes && (actionsRes.total - actionsRes.actions?.length || 0);

  return (
    <LoadingOverlay loading={ isLoading }>
      <Card className={ `w-full h-full` }>
        <MultiButtonCardHeader
          title={ `Today's Draft Actions` }
          description={ `These are draft actions that require your review` }
          noDivider
        />
        <CardContent>
          <When condition={ !hasActions }>
            <div className={ `flex justify-center items-center min-h-[100px]` }>
              <Paragraph>
                { `No manual draft actions due today` }
              </Paragraph>
            </div>
          </When>
          <When condition={ hasActions }>
            {
              Object.keys(actions).map(key => {
                const actionsForType = actions[key as ActionType];

                return (
                  <div
                    key={ key }
                  >
                    <Divider
                      text={ langauge.action[key].labelShort || langauge.action[key].label }
                      weight={ `lighter` }
                      spacing={ `xs` }
                      textPosition={ `left` }
                      textWeight={ `bold` }
                    />
                    {
                      actionsForType.map(action => {
                        return (
                          <a
                            href={ `#` }
                            onClick={ e => {
                              e.preventDefault();
                              onActionClick(action.id);
                            } }
                            key={ action.id }
                          >
                            <div
                              className={ `grid grid-cols-12 gap-4 hover:bg-gray-50 rounded-md py-1` }
                            >
                              <div className={ `col-span-4 flex justify-start` }>
                                <Paragraph
                                  className={ `truncate` }
                                  variant={ `secondary` }
                                >
                                  { action.contact?.name }
                                </Paragraph>
                                <When condition={ !allowedRetrospective.includes(action.type) }>
                                  <Paragraph
                                    as={ `span` }
                                    variant={ `help` }
                                    size={ `xs` }
                                    className={ `mr-1 flex items-center` }
                                  >
                                    <Tooltip
                                      title={ `This action will be removed in ${getHoursRemaining(new Date(action.actionOn), currentOrg?.validatedTimezone)} hours` }
                                    >

                                      <ClockIcon className={ `h-4 w-4 ml-1` } />
                                    </Tooltip>
                                  </Paragraph>
                                </When>
                              </div>

                              <div className={ `col-span-4 flex justify-center` }>
                                <Paragraph
                                  className={ `truncate` }
                                  variant={ `secondary` }
                                >
                                  { getPolicyInfo(action) }
                                </Paragraph>

                              </div>

                              <div className={ `col-span-2 flex justify-center` }>
                                <Paragraph
                                  className={ `truncate` }
                                  variant={ `secondary` }
                                >
                                  { action.invoice?.xero_number || action.quote?.number }
                                </Paragraph>
                              </div>

                              <div className={ `col-span-2 flex justify-end` }>
                                <Paragraph
                                  variant={ `link` }
                                  className={ `flex items-end space-x-2` }
                                >
                                  { `Review` }
                                </Paragraph>
                              </div>
                            </div>
                          </a>
                        );
                      })
                    }
                  </div>
                );
              })
            }
            <When condition={ notShownAmount > 0 }>
              <div className={ `flex justify-end` }>
                <Paragraph
                  variant={ `help` }
                >
                  { `+${notShownAmount} more action${notShownAmount === 1 ? `` : `s`} due today` }
                </Paragraph>
              </div>
            </When>
            <div className={ `flex justify-center pt-4` }>
              <a
                href={ `#` }
                onClick={ goToActions }
              >
                <Paragraph
                  variant={ `link` }
                >
                  { `Review all due draft actions` }
                </Paragraph>
              </a>
            </div>
          </When>
        </CardContent>
      </Card>
    </LoadingOverlay>
  );
}

