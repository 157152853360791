import { Dropdown } from "../../../../common/Atoms/Dropdown";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { useFreezeContactsMutation } from "../../../services/api/contactsApi/contacts";

interface IApplyTagsProps {
  selectedContacts: number[];
}

const FREEZE = `FREEZE`;
const UNFREEZE = `UNFREEZE`;

export function FreezeContacts({ selectedContacts }: IApplyTagsProps) {
  const [freezeContacts, { isLoading: freezeLoading }] = useFreezeContactsMutation();
  const currentOrg = useGetSelectedOrganisation();

  function handleSelect(e: { value: string; label: string }) {
    if (currentOrg?.id) {
      freezeContacts({
        organisationId: currentOrg.id,
        contactIds: selectedContacts,
        freezeState: e.value === FREEZE,
      });
    }
  }

  return (
    <>
      <div>
        <Dropdown
          options={ [
            [
              { value: FREEZE, label: `Freeze ${selectedContacts.length} contacts` },
              { value: UNFREEZE, label: `Unfreeze ${selectedContacts.length} contacts` },
            ],
          ] }
          onSelect={ handleSelect }
          label={ `Freeze Contacts` }
          size={ `md` }
          disabled={ !selectedContacts.length }
          loading={ freezeLoading }
        />
      </div>
    </>
  );
}
