import { CheckCircleIcon, ExclamationCircleIcon, PauseCircleIcon, ArrowLeftCircleIcon, EllipsisHorizontalCircleIcon } from '@heroicons/react/20/solid';
import { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { ActionStatus, ErrorCodes, IActionResponseItem, emailTypes, getError, langauge } from 'shared';
import { When } from 'react-if';
import { useDispatch } from 'react-redux';
import { isBefore } from 'date-fns';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { open } from '../../slices/actionDrawer';
import { useIsSafeMode } from '../../hooks/useIsSafeMode';
import { DatePopover } from '../../../common/Components/DatePopover';

interface TimelineBasicItemProps {
  action: IActionResponseItem
}

export function TimelineBasicItem({ action }: TimelineBasicItemProps) {
  const safemode = useIsSafeMode();
  const actionDate = useMemo(() => {
    if (action.status === ActionStatus.PENDING && isBefore(new Date(action.actionOn), new Date()) && !safemode) {
      return `Within 5 minutes`;
    }

    if (action.executedOn) {
      // Prioritise this date
      return <DatePopover
        date={ action.executedOn }
        labelFormat={ `ccc dd LLL yy` }
        organisationTimezone={ action.organisationTimezone }
      />;
    }

    return <DatePopover
      date={ action.actionOn }
      labelFormat={ `ccc dd LLL yy` }
      organisationTimezone={ action.organisationTimezone }
    />;
  }, [action]);

  return (
    <>
      <div className={ `relative flex h-6 w-6 flex-none items-center justify-center` }>
        <Icon action={ action } />
      </div>
      <p className={ `flex-auto py-0.5 text-xs leading-5 text-gray-500` }>
        <Message action={ action } />
      </p>
      <div
        className={ `flex-none py-0.5 text-xs leading-5 text-gray-500` }
      >
        { actionDate }
      </div>
    </>
  );
}

interface IconProps {
  action: IActionResponseItem
}

function Icon({ action }: IconProps) {
  if (action.status === ActionStatus.FAILED) {
    return (
      <Tooltip title={ `Click "Recalcuate" to remove and retry` }>
        <ExclamationCircleIcon className={ `h-6 w-6 text-red-500` }
          aria-hidden={ `true` } />
      </Tooltip>
    );
  }

  if (action.status === ActionStatus.COMPLETE) {
    return <CheckCircleIcon className={ `h-6 w-6 text-green-500` }
      aria-hidden={ `true` } />;
  }

  if (action.status === ActionStatus.PENDING) {
    return <EllipsisHorizontalCircleIcon className={ `h-6 w-6 text-lateGreen-500` }
      aria-hidden={ `true` } />;
  }

  if (action.status === ActionStatus.SUSPENDED || action.status === ActionStatus.APPROVAL_PENDING) {
    return <PauseCircleIcon className={ `h-6 w-6 text-yellow-500` }
      aria-hidden={ `true` } />;
  }

  if (action.status === ActionStatus.REVERTED) {
    return <ArrowLeftCircleIcon className={ `h-6 w-6 text-orange-500` }
      aria-hidden={ `true` } />;
  }
}

interface IMessageProps {
  action: IActionResponseItem
}

function Message({ action }: IMessageProps) {
  const dispatch = useDispatch();
  const actionType = useMemo(() => {
    return langauge.action[action.type].timelineLabel ;
  }, [action]);

  const actionVerb = useMemo(() => {
    const str = langauge.action[action.type].status[ActionStatus.COMPLETE].toLowerCase();

    if (emailTypes.includes(action.type) && action?.payload?.contactPerson?.email) {
      return `${str} to '${action.payload.contactPerson.email}'`;
    }

    return str;
  }, [action]);

  const withTriggerPolicy = useMemo(() => {
    // Discounts are not using the action_policy table
    const policies = action.discount?.policyTitle ?
      [action.discount.policyTitle] :
      action.policies.map(p => p.title);

    if (!policies.length) {
      return ``;
    }

    if (action.policies.some(p => p.system)) {
      return ` using manual trigger`;
    }

    const policyWord = policies.length === 1 ? `policy` : `policies`;

    return ` using ${policyWord} (${policies.join(`, `)})`;
  }, [action]);

  function onActionClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();

    dispatch(open(action.id));
  }

  const link = (
    <Paragraph as={ `span` }>
      <a
        href={ `#` }
        onClick={ onActionClick }
      >
        { actionType }
        <ArrowTopRightOnSquareIcon className={ `h-4 w-4 ml-1 inline-flex self-center` } />
      </a>
    </Paragraph>
  );

  if (action.status === ActionStatus.FAILED) {
    return (
      <>
        { `A` }
        { ` ` }
        { link }
        { ` ` }
        { `failed with error:` }
        <span className={ `font-medium text-gray-600` }>
          { action.errorMessage }
        </span>
      </>
    );
  }

  if (action.status === ActionStatus.COMPLETE) {
    return (
      <>
        { `A` }
        { ` ` }
        { link }
        { ` ` }
        { `was ` }{ actionVerb }{ withTriggerPolicy }
        <When condition={ action.ignored }>
          { ` [Ignored]` }
        </When>
      </>
    );
  }

  if (action.status === ActionStatus.PENDING) {
    return (
      <>
        { `A` }
        { ` ` }
        { link }
        { ` ` }
        { `will be ` }{ actionVerb }{ withTriggerPolicy }
      </>
    );
  }

  if (action.status === ActionStatus.APPROVAL_PENDING) {
    return (
      <>
        { `A` }
        { ` ` }
        { link }
        { ` ` }
        { `is drafted (Requires Manual Run) ` }{ withTriggerPolicy }
      </>
    );
  }

  if (action.status === ActionStatus.SUSPENDED) {
    return (
      <>
        { `A` }
        { ` ` }
        { link }
        { ` ` }
        { `was suspended with note:` }
        <span className={ `font-medium text-gray-600` }>
          { ` `+ getError(`short`, action.errorCode as ErrorCodes, action.errorMessage) }
        </span>
      </>
    );
  }

  if (action.status === ActionStatus.REVERTED) {
    return (
      <>
        { `A` }
        { ` ` }
        { link }
        { ` ` }
        { `was reverted.` }
      </>
    );
  }
}
