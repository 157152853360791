import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// import { useEffect } from 'react';
import { ActionSortTypes, ActionStatus, ActionType, IActionParams } from 'shared';
import { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { DateTime } from 'luxon';

import { useFetchActionsQuery, useFetchDashboardActionsQuery } from '../services/api/actionApi/action';
import { RootState } from '../store';
import { reset, resetFilter, resetPagination, resetSort, setCurrentTab, setFilterModel, setPaginationModel, setSortModel } from '../slices/actionTableModels';

import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

export enum QuickFilterTypes {
  pendingAndDue = `pendingAndDue`,
  recentlyCompleted = `recentlyCompleted`,
}

export const initialState: IActionParams = {
  actionOnMinDate: null,
  actionOnMaxDate: null,
  executedOnMinDate: null,
  executedOnMaxDate: null,
  invoiceId: null,
  organisationId: null,
  type: null,
  status: null,
  page: 1,
  limit: 300,
  sortBy: `actionOn`,
  sortOrder: `asc`,
  invoiceNumberText: null,
  invoiceContactText: null,
  contactId: null,
  groupId: null,
  policyId: null,
  quickSearchStr: null,
  dashboardSearch: false,
};

/* MUI records the timestamp using system time, adjust this to org time */
function adjustDate(date: Date | string | null, timezone?: string) {
  if (!date) {
    return null;
  }

  if (typeof date === `string`) {
    date = new Date(date);
  }

  if (!timezone) {
    return date.toISOString();
  }

  const dt = DateTime.fromJSDate(date);
  const day = dt.day;
  const month = dt.month;
  const year = dt.year;
  const hour = dt.hour;
  const minute = dt.minute;
  const second = dt.second;

  // Keep the time the same, but adjust the timezone
  const newDate = DateTime
    .fromObject({ year, month, day, hour, minute, second }, { zone: timezone })
    .toISO();

  return newDate;
}

export function useActions() {
  const currentOrg = useGetSelectedOrganisation();
  const { organisationId, invoiceId, groupId, contactId } =
    useParams<{ organisationId: string; invoiceId?: string; groupId?: string, contactId?: string}>();

  const {
    filterModel,
    sortModel,
    paginationModel,
    currentTab,
  } = useActionTableModels();

  // Extract and build the params object
  const page = paginationModel.page + 1; // MUI is 0-based
  const limit = paginationModel.pageSize;

  // Filters
  const status = currentTab || null as ActionStatus | null;
  const type = filterModel.items.find(item => item.field === `type`)?.value || null as ActionType | null;
  const invoiceContactText = filterModel.items.find(item => item.field === `contact`)?.value || null;
  const invoiceNumberText = filterModel.items.find(item => item.field === `invoiceNumber`)?.value || null;
  const policyId = filterModel.items.find(item => item.field === `policyId`)?.value || null;
  const actionOnMinDate = filterModel.items.find(item => item.field === `actionOn` && item.operator === `onOrAfter`)?.value || null;
  const actionOnMaxDate = filterModel.items.find(item => item.field === `actionOn` && item.operator === `onOrBefore`)?.value || null;
  const executedOnMinDate = filterModel.items.find(item => item.field === `executedOn` && item.operator === `onOrAfter`)?.value || null;
  const executedOnMaxDate = filterModel.items.find(item => item.field === `executedOn` && item.operator === `onOrBefore`)?.value || null;
  const quickSearchStr = filterModel.quickFilterValues?.[0] || null;

  // Sort
  // Todo - Multi sort?
  const sortBy = (sortModel[0]?.field || null) as ActionSortTypes | null;
  const sortOrder = sortModel[0]?.sort || null;

  let paramObject: IActionParams = {
    organisationId,
    page,
    limit,

    // Filters
    status,
    type,
    invoiceContactText,
    invoiceNumberText,
    policyId,
    quickSearchStr,

    // Date filters
    actionOnMinDate: adjustDate(actionOnMinDate, currentOrg?.validatedTimezone),
    actionOnMaxDate: adjustDate(actionOnMaxDate, currentOrg?.validatedTimezone),
    executedOnMinDate: adjustDate(executedOnMinDate, currentOrg?.validatedTimezone),
    executedOnMaxDate: adjustDate(executedOnMaxDate, currentOrg?.validatedTimezone),

    // Sort
    sortBy,
    sortOrder,

    // actionOnMinDate: params.actionOnMinDate,
    // actionOnMaxDate: params.actionOnMaxDate,
    // executedOnMaxDate: params.executedOnMaxDate,
    // executedOnMinDate: params.executedOnMinDate,
  };

  if (invoiceId) {
    // When invoice ID is present, reset all other params
    paramObject = {
      ...initialState,
      organisationId,
      invoiceId,
    };
  }

  if (groupId) {
    paramObject.groupId = Number(groupId);
    paramObject.invoiceId = null;
    paramObject.contactId = null;
  }

  if (contactId) {
    paramObject.contactId = Number(contactId);
    paramObject.invoiceId = null;
    paramObject.groupId = null;
  }

  const result = useFetchActionsQuery(paramObject, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
    pollingInterval: 1000 * 15, // 15 seconds
    skipPollingIfUnfocused: true,
  });

  if (result.data?.actions && result.data.actions.length && invoiceId) {
    result.data = {
      ...result.data,
      actions: result.data.actions.filter(action => action.invoiceId === invoiceId),
    };
  }

  return result;
}

export function useDashboardActions(skip?: boolean) {
  const currentOrg = useGetSelectedOrganisation();
  const params: IActionParams = {
    status: ActionStatus.APPROVAL_PENDING,
    actionOnMaxDate: DateTime.now().endOf(`day`).toISO(),
    organisationId: currentOrg?.id,
    dashboardSearch: true,
  };

  const result = useFetchDashboardActionsQuery(params, {
    skip: !currentOrg?.id || skip,
    pollingInterval: 1000 * 15, // 15 seconds
    skipPollingIfUnfocused: true,
  });

  return result;
}

const sortByActionOnStatus: ActionStatus[] = [
  ActionStatus.PENDING,
  ActionStatus.APPROVAL_PENDING,
  ActionStatus.FAILED,
  ActionStatus.SUSPENDED,
];

const sortByExecutedOnStatus: ActionStatus[] = [
  ActionStatus.COMPLETE,
  ActionStatus.REVERTED,
];

export function useActionTableModels() {
  const dispatch = useDispatch();
  const actionTableModels = useSelector((s: RootState) => s.actionTableModels);
  const currentOrg = useGetSelectedOrganisation();

  function onQuickFilter(type: QuickFilterTypes) {
    dispatch(reset());
    // Preserve status
    dispatch(setCurrentTab(actionTableModels.currentTab));
    switch (type) {
    case QuickFilterTypes.pendingAndDue:
      dispatch(setFilterModel({
        items: [
          {
            field: `actionOn`,
            operator: `onOrBefore`,
            value: currentOrg?.currentTime || new Date().toISOString(),
            id: Math.round(Math.random() * 1e5),
          },
        ],
      }));

      dispatch(setSortModel([
        {
          field: `actionOn`,
          sort: `asc`,
        },
      ]));
      break;
    case QuickFilterTypes.recentlyCompleted:
      dispatch(setFilterModel({
        items: [
          {
            field: `executedOn`,
            operator: `onOrAfter`,
            value: DateTime.now().minus({ days: 2 }).startOf(`day`).toISO(), // Start of day before yesterday in local time
            id: Math.round(Math.random() * 1e5),
          },
        ],
      }));

      dispatch(setSortModel([
        {
          field: `executedOn`,
          sort: `desc`,
        },
      ]));
      break;

    default:
      break;
    }
  }

  function onStatusChange(status: ActionStatus) {
    // Make sure no date filters are applied
    dispatch(setFilterModel({
      items: [
        {
          field: `executedOn`,
          operator: `onOrAfter`,
          value: null,
          id: Math.round(Math.random() * 1e5),
        },
        {
          field: `actionOn`,
          operator: `onOrAfter`,
          value: null,
          id: Math.round(Math.random() * 1e5),
        },
      ],
    }));

    if (sortByActionOnStatus.includes(status)) {
      dispatch(setSortModel([
        {
          field: `actionOn`,
          sort: `asc`,
        },
      ]));
    }

    if (sortByExecutedOnStatus.includes(status)) {
      dispatch(setSortModel([
        {
          field: `executedOn`,
          sort: `desc`,
        },
      ]));
    }

    dispatch(setCurrentTab(status));
  }

  return {
    // State
    filterModel: actionTableModels.filterModel,
    sortModel: actionTableModels.sortModel,
    paginationModel: actionTableModels.paginationModel,
    currentTab: actionTableModels.currentTab,

    // Actions
    setFilterModel: (model: GridFilterModel) => dispatch(setFilterModel(model)),
    setSortModel: (model: GridSortModel) => dispatch(setSortModel(model)),
    setPaginationModel: (model: GridPaginationModel) => dispatch(setPaginationModel(model)),
    resetModels: () => dispatch(reset()),
    resetSort: () => dispatch(resetSort()),
    resetFilter: () => dispatch(resetFilter()),
    resetPagination: () => dispatch(resetPagination()),
    setCurrentTab: (tab: ActionStatus) => onStatusChange(tab),
    onQuickFilter,
  };
}
