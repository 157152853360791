import { EmailTemplateTypes, PolicyType } from "shared";
import { useMemo } from "react";

import { useAdhocActionModal } from "../../../../hooks/useAdhocActionModal";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { FormRow } from "../../../Molecules/Form/FormRow";
import { FormLabel } from "../../../../../common/Atoms/FormLabel";
import { FormDescription } from "../../../../../common/Atoms/FormDescription";
import { TextEditor } from "../../../Molecules/TextEditor";
import { useMergeTags } from "../../../../hooks/useMergeTagsAndLinks";
import { SMSDefaultCountryCode } from "../PolicyForm/Fields/SMSDefaultCountryCode";
import { SMSFallbackToPhone } from "../PolicyForm/Fields/SMSFallbackToPhone";

export function AdhocSms() {
  const { type, currentSystemPolicy, currentSystemPolicyForm, update, invoiceId, invoiceNumber, invalids } = useAdhocActionModal();

  const mergeTags = useMergeTags(EmailTemplateTypes.SMS); // TODO: Other types

  const formattedMergeTags = useMemo(() => {
    return mergeTags.asArray
      .filter(tag => tag.value !== `ForecastedPenaltyAmount` && !tag.archived)
      .map(tag => {
        return {
          text: tag.name,
          value: tag.value,
        };
      });
  }, [ mergeTags ]);

  if (!currentSystemPolicy || !currentSystemPolicyForm) return null;

  if (!type) return null;

  if (type !== PolicyType.SMS || currentSystemPolicy.policy_type !== PolicyType.SMS) return null;

  if (!invoiceId) return null;

  function handleSmsContentChange({ text }) {
    update({
      name: `sms_content`,
      value: text,
    });
  }

  return (
    <div>
      <Heading>
        { `Send a one off SMS to ${invoiceNumber || `[No Invoice Number]`}` }
      </Heading>

      <div className={ `p-4 space-y-4` }>
        <FormRow className={ `space-y-1` }>
          <FormLabel>{ `Text Message Content` }</FormLabel>
          <FormDescription>{ `Use merge tags to dynamic insert values. Maximum 320 characters` }</FormDescription>
          <TextEditor
            value={ currentSystemPolicyForm.sms_content }
            onChange={ handleSmsContentChange }
            mergeTags={ formattedMergeTags }
            mode={ `sms` }
          />
        </FormRow>

        <SMSFallbackToPhone
          policyFormData={ currentSystemPolicyForm }
          invalids={ invalids }
          handleChange={ update }
        />

        <SMSDefaultCountryCode
          policyFormData={ currentSystemPolicyForm }
          invalids={ invalids }
          handleChange={ update }
        />
      </div>
    </div>
  );
}
