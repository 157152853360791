import React from 'react';
import { When } from 'react-if';

import { classNames } from '../lib/classNames';

interface DividerProps {
  text?: string
  weight?: `light` | `normal` | `bold` | `lightest` | `lighter`;
  spacing?: `small` | `medium` | `xs` | `none`;
  textPosition?: `center` | `left` | `right`;
  textWeight?: `light` | `normal` | `bold`;
  textSize?: `xs` | `small` | `medium` | `large`;
}

const weights = {
  lightest: `border-gray-50`,
  lighter: `border-gray-100`,
  light: `border-gray-200`,
  normal: `border-gray-300`,
  bold: `border-gray-500`,
};

const spacings = {
  none: ``,
  xs: `py-2`,
  small: `py-4`,
  medium: `py-6`,
};

export function Divider({
  text,
  weight = `normal`,
  spacing = `medium`,
  textPosition = `center`,
  textWeight = `normal`,
  textSize = `small`,
}: DividerProps) {
  return (
    <div className={
      classNames(
        `relative`,
        spacings[spacing],
      )
    }>
      <div className={ `absolute inset-0 flex items-center` }
        aria-hidden={ `true` }>
        <div className={
          classNames(
            `w-full`,
            `border-t`,
            weights[weight],
          )
        }
        />
      </div>
      <When condition={ text }>
        <div className={ classNames(
          `relative flex rounded-lg`,
          textPosition === `center` && `justify-center`,
          textPosition === `left` && `justify-start`,
          textPosition === `right` && `justify-end`,
        ) }>
          <span className={ classNames(
            `bg-white text-sm text-gray-500 rounded-xl`,
            textWeight === `light` && `font-light`,
            textWeight === `normal` && `font-normal`,
            textWeight === `bold` && `font-bold`,
            textSize === `xs` && `text-xs`,
            textSize === `small` && `text-sm`,
            textSize === `medium` && `text-base`,
            textSize === `large` && `text-lg`,
            textPosition === `left` && `pr-1`,
            textPosition === `right` && `pl-1`,
            textPosition === `center` && `px-2`,
          ) }>
            { text }
          </span>
        </div>
      </When>
    </div>
  );
}
