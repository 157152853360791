import { When } from "react-if";

import { LoadingElementPlaceholder } from "../../../../common/Atoms/LoadingElementPlaceholder";
import { useContact } from "../../../hooks/useContact";
import { Notification } from "../../../../common/Atoms/Notification";
import { Button } from "../../../../common/Atoms/Button";
import { useRenableCallsMutation } from "../../../services/api/contactsApi/contact";

import { ContactHeading } from "./ContactHeadingSection/ContactHeading";
import { ContactPersons } from "./ContactPersonsSection/ContactPersons";
import { ContactTableSectionRoot } from "./ContactTableSection/ContactTableSectionRoot";

export function ContactPageRoot() {
  const { isLoading, data: contact } = useContact();
  const [renableCalls, { isLoading: isRenableCallsLoading }] = useRenableCallsMutation();

  function onCanCall() {
    if (!contact) return;

    renableCalls({ organisationId: contact?.organisationId, contactId: contact?.id });
  }

  const canCallButton = (
    <Button
      onClick={ onCanCall }
      color={ `orange` }
      loading={ isRenableCallsLoading }
      disabled={ isRenableCallsLoading }
    >
      { `Renable Calls` }
    </Button>
  );

  return (
    <div className={ `grid grid-cols-12 gap-2` }>
      <div className={ `col-span-12` }>
        {
          isLoading ? (
            <LoadingElementPlaceholder />
          ) : (
            <ContactHeading/>
          )
        }
      </div>

      <When condition={ contact?.doNotCall }>
        <div className={ `col-span-12` }>
          <Notification
            type={ `warning` }
            title={ `Contact marked as Do Not Call` }
            buttons={ canCallButton }
          >
            { contact?.doNotCallReason }
          </Notification>
        </div>
      </When>

      <When condition={ contact?.smsOptOut }>
        <div className={ `col-span-12` }>
          <Notification
            type={ `warning` }
            title={ `SMS Disabled` }
          >
            { `This contact has opted out of SMS communications, to re-enable ask them to grant permission on their Paidnice portal page.` }
          </Notification>
        </div>
      </When>

      <div className={ `col-span-4` }>
        {
          isLoading ? (
            <LoadingElementPlaceholder />
          ) : (
            <ContactPersons />
          )
        }
      </div>

      <div className={ `col-span-8` }>
        {
          isLoading ? (
            <LoadingElementPlaceholder rows={ 10 }/>
          ) : (
            <ContactTableSectionRoot />
          )
        }
      </div>
    </div>
  );
}
