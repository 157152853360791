import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { FolderPlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { When } from 'react-if';

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { CardContent } from '../../../../common/Atoms/CardContent';
import { useGetSelectedOrganisation } from '../../../hooks/useGetSelectedOrganisation';
import { useFiles } from '../../../hooks/useFiles';
import { useDeleteFileMutation, useUploadFileMutation } from '../../../services/api/filesApi/files';
import { Paragraph } from '../../../../common/Atoms/Typography/Paragraph';
import { SmallSpinner } from '../../../../common/Atoms/SmallSpinner';

export function FilesRoot() {
  const currentOrg = useGetSelectedOrganisation();
  const fileUploderRef = useRef(null);
  const { data: filesResponse } = useFiles();
  const [ uploadFile, { isLoading: isUploading } ] = useUploadFileMutation();
  const [ deleteFile ] = useDeleteFileMutation();
  const [ deleting, setDeleting ] = useState<number | null>(null);

  function onUploadClick() {
    // Programmatically click the file uploader
    if (fileUploderRef.current?.click) {
      fileUploderRef?.current?.click();
    }
  }

  async function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const fileUploaded = event.target.files[0];

    const formData = new FormData();

    formData.append(`file`, fileUploaded);

    const response = await uploadFile({
      org: currentOrg?.id,
      formData,
    });

    console.log(response);

    fileUploderRef.current.value = null;
  }

  const filesTree = useMemo(() => {
    if (!filesResponse || !filesResponse.files) return [];

    return filesResponse.files.map(f => ({
      ...f,
      name: f.originalFileName,
      id: f.id,
    }));
  }, [filesResponse]);

  async function onDelete(id: number) {
    setDeleting(id);

    await deleteFile({
      org: currentOrg?.id,
      id,
    });

    setDeleting(null);
  }

  return (
    <Card>
      <input
        type={ `file` }
        ref={ fileUploderRef }
        onChange={ handleChange }
        className={ `hidden` }
      />
      <MultiButtonCardHeader
        title={ `Files` }
        description= { `Manage your file uploads, for use in email attachments. E.g Your terms and conditions.` }
        secondaryDescription={ `Max file size: 10MB` }
        buttons={ [
          {
            buttonText: `Upload File`,
            onClick: onUploadClick,
            icon: FolderPlusIcon,
            loading: isUploading,
            disabled: isUploading,
          },
        ] }
      />

      <CardContent className={ `mt-4` }>
        <When condition={ isUploading }>
          <Paragraph>
            { `Uploading file...` }
          </Paragraph>
        </When>
        <SimpleTreeView
          expandedItems={ [`root`] }
        >
          <TreeItem
            itemId={ `root` }
            label={ `${currentOrg?.legalName}'s Files` }
          >
            { filesTree.map(file => {
              const labelNode = (
                <div className={ `grid grid-cols-12` }>
                  <Paragraph className={ `col-span-6` }>
                    { file.name }
                  </Paragraph>

                  <Paragraph className={ `col-span-2` }>
                    { file.sizeFormatted }
                  </Paragraph>

                  <Paragraph className={ `col-span-2` }>
                    { new Date(file.uploadedAt).toLocaleString() }
                  </Paragraph>

                  <span className={ `col-span-2 flex space-x-2` }>
                    { /* <button
                      onClick={ () => onDownload(file.id) }
                    >
                      <EyeIcon className={ `h-6 w-6 text-gray-500` } />
                    </button> */ }

                    <button
                      onClick={ () => onDelete(file.id) }
                      disabled={ deleting === file.id }
                    >
                      <When condition={ deleting === file.id }>
                        <SmallSpinner colored />
                      </When>
                      <When condition={ deleting !== file.id }>
                        <TrashIcon className={ `h-6 w-6 text-gray-500` } />
                      </When>
                    </button>
                  </span>
                </div>
              );

              return (
                <TreeItem
                  key={ file.id }
                  itemId={ file.id.toString() }
                  label={ labelNode }
                />
              );
            }) }
          </TreeItem>
        </SimpleTreeView>
      </CardContent>
    </Card>
  );
}
