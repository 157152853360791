import { useParams } from "react-router";

import { useFetchOrganisationMetricsQuery } from "../services/api/organisationApi/organisation";

export function useOrgMetrics(groupId?: number) {
  const { organisationId } = useParams<{ organisationId: string }>();

  return useFetchOrganisationMetricsQuery(
    {
      organisationId: organisationId,
      groupId: groupId || undefined,
    },
    {
      skip: !organisationId,
      refetchOnMountOrArgChange: true,
      pollingInterval: 1000 * 30, // 30 seconds
      skipPollingIfUnfocused: true,
    },
  );
}
