import { ReactElement, useMemo } from "react";

import { classNames } from "../../../../common/lib/classNames";
import { env } from "../../../../common/lib/env";
import { useUser } from "../../../hooks/useUser";
import { PendingInvitations } from "../../Common/PendingInvitations";

import { InvoiceOverdueChart } from "./InvoiceOverdueChart";
// import { OverdueAgeChart } from "./OverdueAgeChart";
// import { ActionSummaryChartV2 } from "./ActionSummaryChartV2";
import { Stats } from "./Stats";
import { InvoiceValue } from "./InvoiceValue";
import { BadContacts } from "./BadContacts";
import { EmailDeliveryWidget } from "./EmailDeliveryWidget";
import { InvoiceHistory } from "./InvoiceHistory";
import { BadInvoices } from "./BadInvoices";
import { ActionableTasks } from "./ActionableTasks";

interface WidgetConfig {
  component: () => ReactElement,
  span: {
    small: 1 | 2 | `full`;
    desktop: 1 | 2 | 3 | 4 | 5 | 6 | `full`;
  }
}

export function WidgetsRoot() {
  const { data: user } = useUser();

  /** TODO - This will be where users customise dash? */
  const widgets: WidgetConfig[] = useMemo(() => {
    const w: WidgetConfig[] = [
      {
        component: () => <InvoiceValue />,
        span: {
          small: 2,
          desktop: 3,
        },
      },
      {
        component: ActionableTasks,
        span: {
          small: 2,
          desktop: 3,
        },
      },
      {
        component: BadContacts,
        span: {
          small: 2,
          desktop: 3,
        },
      },
      {
        component: InvoiceHistory,
        span: {
          small: 2,
          desktop: 3,
        },
      },
      {
        component: BadInvoices,
        span: {
          small: 2,
          desktop: 3,
        },
      },
      {
        component: EmailDeliveryWidget,
        span: {
          small: 2,
          desktop: 3,
        },
      },
      {
        component: InvoiceOverdueChart,
        span: {
          small: 2,
          desktop: 3,
        },
      },
    ];

    if (!user) {
      return w;
    }

    // if (user.adminOverride || env.isDev) {
    //   console.log(`Adding experimental widgets for admin override`);

    //   w.push({
    //     component: ActionSummaryChartV2,
    //     span: {
    //       small: 2,
    //       desktop: 6,
    //     },
    //   });
    // }

    return w;
  }, [user, env, InvoiceValue, Stats, BadContacts, InvoiceHistory, BadInvoices, EmailDeliveryWidget, InvoiceOverdueChart, ActionableTasks]);

  // Need to declare for CSS gen
  const colClassNamesSmall = {
    1: `col-span-1`,
    2: `col-span-2`,
    full: `col-span-2`,
  };

  const colClassNamesDesktop = {
    1: `lg:col-span-1`,
    2: `lg:col-span-2`,
    3: `lg:col-span-3`,
    4: `lg:col-span-4`,
    5: `lg:col-span-5`,
    6: `lg:col-span-6`,
    [`full`]: `lg:col-span-6`,
  };

  return (
    // 4 columns, except on small/small screen
    <>
      <PendingInvitations showContentOnly={ false } />
      <div className={ `grid grid-cols-2 lg:grid-cols-6 gap-2 grow` }>
        {
          widgets.map((widget, i) => (
            <div
              key={ i }
              className={
                classNames(
                  colClassNamesSmall[widget.span.small],
                  colClassNamesDesktop[widget.span.desktop],
                )
              }>
              <widget.component/>
            </div>
          ))
        }
      </div>
    </>
  );
}
