import { IGetFilesResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export const filesApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchFiles: builder.query<IGetFilesResponse, string>({
      query: org => ({
        url: `files`,
        method: `GET`,
        headers: {
          [orgHeader]: org,
        },
      }),
      providesTags: result => result ?
        [
          ...result.files?.map(({ id }) => ({ type: CacheTags.Files, id })),
          { type: CacheTags.Files, id: `LIST` },
        ]
        : [{ type: CacheTags.Files, id: `LIST` }],
    }),
    uploadFile: builder.mutation<void, { org: string, formData: FormData }>({
      query: ({ org, formData }) => ({
        url: `files`,
        method: `POST`,
        headers: {
          [orgHeader]: org,
          'Content-Type': `multipart/form-data`,
        },
        data: formData,
      }),
      invalidatesTags: [{ type: CacheTags.Files, id: `LIST` }],
    }),
    deleteFile: builder.mutation<void, { org: string, id: number }>({
      query: ({ org, id }) => ({
        url: `files/${id}`,
        method: `DELETE`,
        headers: {
          [orgHeader]: org,
        },
      }),
      invalidatesTags: () => [{ type: CacheTags.Files, id: `LIST` }],
    }),
  }),
});

export const {
  useFetchFilesQuery,
  useUploadFileMutation,
  useDeleteFileMutation,
} = filesApi;
