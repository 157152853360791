import { CheckCircleIcon, FaceFrownIcon, FaceSmileIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { QuoteEventType } from "shared";

export function getQuoteIcon(type: QuoteEventType) {
  switch (type) {
  case QuoteEventType.IMPORTED:
    return CheckCircleIcon;
  case QuoteEventType.ACCEPTED:
    return FaceSmileIcon;
  case QuoteEventType.DECLINED:
    return FaceFrownIcon;
  case QuoteEventType.SENT:
    return PaperAirplaneIcon;
  default:
    return FaceSmileIcon;
  }
}
