import { IGroup, IGroupResponse } from 'shared';
import { IGroupUpdate } from 'shared/dist/cjs/apiContract/request/group';

import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export const groupApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchGroups: builder.query<IGroup[], string>({
      query: org => ({
        url: `group`,
        method: `GET`,
        headers: {
          [orgHeader]: org,
        },
      }),
      transformResponse: (response: IGroupResponse) => {
        return response?.groups;
      },
      providesTags: result => result &&
        [
          ...result.map(({ id }) => ({ type: CacheTags.Groups, id })),
          { type: CacheTags.Groups, id: `LIST` },
        ],
    }),
    fetchGroupsAsWorkflows: builder.query({
      query: org => ({
        url: `group/workflows`,
        method: `GET`,
        headers: {
          [orgHeader]: org,
        },
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (response: any) => {
        return response?.workflows;
      },
      providesTags: result => result &&
        [
          ...result.map(({ id }) => ({ type: CacheTags.Groups, id })),
          { type: CacheTags.Groups, id: `LIST` },
        ],
    }),
    createGroup: builder.mutation({
      query: ({ organisationId }) => {
        return ({
          url: `group`,
          method: `POST`,
          headers: {
            [orgHeader]: organisationId,
          },
        });
      },
      invalidatesTags: [
        { type: CacheTags.Groups, id: `LIST` },
        { type: CacheTags.Organisations, id: `LIST` }, // To update usage,
      ],
    }),
    updateGroup: builder.mutation<void, {
      organisationId: string;
      id: number;
      update: IGroupUpdate;
    }>({
      query: ({ organisationId, id, update }) => ({
        url: `group/${id}`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: update,
      }),
      invalidatesTags: [
        { type: CacheTags.Groups, id: `LIST` },
        { type: CacheTags.Policies, id: `LIST` },
        { type: CacheTags.ActionCreateStatus, id: `LIST` },
      ],
    }),
    updatePolicies: builder.mutation({
      query: ({ organisationId, id, policies }) => ({
        url: `group/${id}/policies`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          policies,
        },
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Groups, id },
        { type: CacheTags.Policies, id: `LIST` },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.ActionCreateStatus, id: `LIST` },
      ],
    }),
    updateRulePriority: builder.mutation({
      query: ({ organisationId, id, ruleId, type }) => ({
        url: `group/${id}/rule/${ruleId}/priority/${type}`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Groups, id },
        { type: CacheTags.Policies, id: `LIST` },
        { type: CacheTags.ActionCreateStatus, id: `LIST` },
      ],
    }),
    addContactsToGroup: builder.mutation({
      query: ({ organisationId, id, contactIds }) => ({
        url: `group/${id}/contact`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          contactIds,
        },
      }),
      invalidatesTags: [{
        type: CacheTags.Contacts,
        id: `LIST`,
      },
      { type: CacheTags.Actions, id: `LIST` },
      { type: CacheTags.ActionCreateStatus, id: `LIST` },
      {
        type: CacheTags.Groups,
        id: `LIST`,
      }],
    }),
    deleteGroup: builder.mutation({
      query: ({ organisationId, id }) => ({
        url: `group/${id}`,
        method: `DELETE`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Groups, id },
        { type: CacheTags.Policies, id: `LIST` },
        { type: CacheTags.Organisations, id: `LIST` }, // To update usage,
        { type: CacheTags.Contacts, id: `LIST` },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.ActionCreateStatus, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchGroupsQuery,
  useFetchGroupsAsWorkflowsQuery,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
  useAddContactsToGroupMutation,
  useUpdatePoliciesMutation,
  useUpdateRulePriorityMutation,
} = groupApi;
