import { IPolicy } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export const systemPoliciesApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchSystemPolicies: builder.query<IPolicy[], { orgId: string}>({
      query: ({ orgId }) => ({
        url: `policy/system`,
        method: `GET`,
        headers: {
          [orgHeader]: orgId,
        },
      }),
      providesTags: [
        { type: CacheTags.SystemPolicies, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchSystemPoliciesQuery,
} = systemPoliciesApi;
