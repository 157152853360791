import { IPolicyPreviewResponse, IPolicyResponse, IPolicyUpdateRequest } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export interface IUpdatePolicy extends IPolicyUpdateRequest {
  organisationId: string;
  id: number;
}

export const policyApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchPolicies: builder.query<IPolicyResponse, string>({
      query: org => ({
        url: `policy`,
        method: `GET`,
        headers: {
          [orgHeader]: org,
        },
      }),
      providesTags: result => result ?
        [
          ...result.policies.map(({ policy }) => ({ type: CacheTags.Policies, id: policy.id })),
          { type: CacheTags.Policies, id: `LIST` },
        ]
        : [{ type: CacheTags.Policies, id: `LIST` }],
    }),
    createPolicy: builder.mutation({
      query: ({ organisationId, type, groupId }) => {
        return ({
          url: `policy`,
          method: `POST`,
          headers: {
            [orgHeader]: organisationId,
          },
          data: {
            policy_type: type,
            groupId,
          },
        });
      },
      invalidatesTags: [
        { type: CacheTags.Policies, id: `LIST` },
        { type: CacheTags.Organisations, id: `LIST` }, // To update usage,
        { type: CacheTags.Groups, id: `LIST` },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.ActionCreateStatus, id: `LIST` },
        { type: CacheTags.EmailTemplates, id: `LIST` },
      ],
    }),
    updatePolicy: builder.mutation<unknown, IUpdatePolicy>({
      query: ({ organisationId, id, ...update }) => ({
        url: `policy/${id}`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: update,
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Policies, id },
        { type: CacheTags.Groups, id: `LIST` },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.ActionCreateStatus, id: `LIST` },
        { type: CacheTags.EmailTemplates, id: `LIST` },
        { type: CacheTags.Organisations, id: `LIST` }, // To update system policies
        { type: CacheTags.SystemPolicies, id: `LIST` },
      ],
    }),
    deletePolicy: builder.mutation({
      query: ({ organisationId, id }) => ({
        url: `policy/${id}`,
        method: `DELETE`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Policies, id },
        { type: CacheTags.Groups, id: `LIST` },
        { type: CacheTags.Organisations, id: `LIST` }, // To update usage,
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.ActionCreateStatus, id: `LIST` },
        { type: CacheTags.EmailTemplates, id: `LIST` },
      ],
    }),
    previewPolicy: builder.query<IPolicyPreviewResponse, {
      organisationId: string,
      policyId: number,
      invoiceId: string,
      contactId: number,
    }>({
      query: ({ organisationId, policyId, invoiceId, contactId }) => ({
        url: `policy/${policyId}/preview`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
        params: {
          invoiceId,
          contactId,
        },
      }),
      providesTags: [
        { type: CacheTags.PreviewPolicy, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchPoliciesQuery,
  useCreatePolicyMutation,
  useDeletePolicyMutation,
  useUpdatePolicyMutation,
  useLazyFetchPoliciesQuery,
  usePreviewPolicyQuery,
  useLazyPreviewPolicyQuery,
} = policyApi;
