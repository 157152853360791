import { TourProvider } from '@reactour/tour';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { PostHogProvider } from 'posthog-js/react';
import { createPortal } from 'react-dom';
import { LicenseInfo } from '@mui/x-license';

import '../common/lib/mixpanel';

import { env } from '../common/lib/env.js';
import { mixpanelService } from '../common/lib/mixpanel';
import { posthogOptions } from '../common/lib/posthog';

import './lib/analytics.js';
import './lib/logrocket.js';
import 'react-toastify/dist/ReactToastify.min.css';
import '../common/css/index.css';
import { App } from './components/App';
import { store } from './store';

import '../common/lib/sentry';

// Allowed to be exposed
// https://mui.com/x/introduction/licensing/#license-key-security
LicenseInfo.setLicenseKey(env.muiLicenceKey);

if (env.mixpanelToken) {
  console.log(`loaded`);
  mixpanelService.pageLoad();
}

if (env.isDev) {
  document.title = `${document.title} ${env.isLocal ? `local`: env.environment}`;
}

// Use test workspace in nonprod
const intercomAppId = env.isProd ? `pphwm0na` : `w3u7sxnn`;

const container = document.getElementById(`app`);
const root = createRoot(container);

// create a portal for the tour
const Wrapper = ({ children }) => {
  return createPortal(children, document.body);
};

document.addEventListener(`DOMContentLoaded`, () => {
  root.render(
    <Provider store={ store }>
      <PostHogProvider
        apiKey={ env.posthogApiKey }
        options={ posthogOptions }
      >
        <IntercomProvider appId={ intercomAppId }>
          <TourProvider
            styles={ {
              popover: base => ({
                ...base,
              }),
            } }
            steps={ [] }
            Wrapper={ Wrapper }
          >
            <App />
          </TourProvider>
        </IntercomProvider>
      </PostHogProvider>
    </Provider>,
  );
});
