// Generic modal to edit and apply adhoc (system) policies

import { Case, Switch, When } from "react-if";
import { policyConfigV2, PolicyType } from "shared";
import { useMemo } from "react";
import { useNavigate } from "react-router";

import { Modal } from "../../../../common/Atoms/Modal";
import { useAdhocActionModal } from "../../../hooks/useAdhocActionModal";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { AdhocStatement } from "../../Settings/Policies/AdhocPolicyTypes/AdhocStatement";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { Button } from "../../../../common/Atoms/Button";
import { AdhocInterestCharge } from "../../Settings/Policies/AdhocPolicyTypes/AdhocInterestCharge";
import { useTrialActionLimitReached } from "../../../hooks/useTrialActionsReached";
import { useGetSubscriptionMetadata } from "../../../hooks/useGetSubscription";
import { Notification } from "../../../../common/Atoms/Notification";
import { AdhocInvoiceEmail } from "../../Settings/Policies/AdhocPolicyTypes/AdhocInvoiceEmail";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useIsSafeMode } from "../../../hooks/useIsSafeMode";
import { AdhocLateFee } from "../../Settings/Policies/AdhocPolicyTypes/AdhocLateFee";
import { AdhocSms } from "../../Settings/Policies/AdhocPolicyTypes/AdhocSms";

import { ResultSection } from "./ResultSection";
import { PreviewSection } from "./PreviewSection";

export function AdhocActionModal() {
  const { show, close, type, loading, saveAndExecute, result, onPreview, view } = useAdhocActionModal();
  const currentOrg = useGetSelectedOrganisation();
  const actionLimitReached = useTrialActionLimitReached();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();
  const navigate = useNavigate();
  const safeModeOn = useIsSafeMode();

  function onStartPlan() {
    if (subscriptionMetadata?.id) {

      close();
      navigate(`/billing/subscriptions/${subscriptionMetadata.id}`);
    }
  }

  const showPreview = useMemo(() => {
    if (!type) return false;

    return policyConfigV2[type]?.previewConfig?.canPreview;
  }, [type, policyConfigV2]);

  const button = showPreview ? (
    <Button
      onClick={ onPreview }
      color={ `green` }
      disabled={ loading || !!result }
    >
      { `Preview` }
    </Button>
  ) : (
    <div className={ `flex flex-col items-end` }>
      <Button
        onClick={ saveAndExecute }
        color={ `orange` }
        disabled={ loading || !!result || actionLimitReached }
      >
        { `Apply Now` }
      </Button>
      <When condition={ safeModeOn }>
        <Paragraph
          variant={ `help` }
        >
          { `This will apply even in safe mode!` }
        </Paragraph>
      </When>
    </div>
  );

  if (!currentOrg) return null;

  return (
    <Modal
      setOpen={ close }
      open={ show }
      title={ `` }
      width={ `stripe` }
    >
      <div className={ `grid grid-cols-1 sm:grid-cols-2` }>
        <div>
          <LoadingOverlay
            loading={ loading }
            message={ `Updating default policy...` }
          >
            <Switch>
              <Case condition={ type === PolicyType.STATEMENT }>
                <AdhocStatement />
              </Case>

              <Case condition={ type === PolicyType.STATEMENT_LATE_FEE }>
                <AdhocInterestCharge />
              </Case>

              <Case condition={ type === PolicyType.REMINDER }>
                <AdhocInvoiceEmail />
              </Case>

              <Case condition={ type === PolicyType.LATE_FEE }>
                <AdhocLateFee />
              </Case>

              <Case condition={ type === PolicyType.SMS }>
                <AdhocSms />
              </Case>
            </Switch>

            <div className={ `flex justify-end pr-4` }>
              { button }
            </div>
          </LoadingOverlay>
        </div>

        { /* Preview / Result Side side */ }
        <div className={ `border-l-gray-100 border-l pl-3 min-h-full flex flex-col justify-between` }>
          <div className={ `` }>
            <Heading>
              { view === `result` ? `Result` : `Preview` }
            </Heading>

            <ResultSection />

            <PreviewSection />

          </div>
          { /* Automation CTA */ }
          <div className={ `flex justify-end` }>
            { /* <Button
              color={ `green` }
            >
              { `Create as Automation` }
            </Button> */ }
          </div>
        </div>
      </div>

      <When condition={ actionLimitReached && !!subscriptionMetadata }>
        { () => (
          <div className={ `mt-4` }>
            <Notification
              type={ `warning` }
              buttons={ (
                <Button
                  color={ `green` }
                  onClick={ onStartPlan }
                >
                  { `Start Plan` }
                </Button>
              ) }
            >
              { `You have reached your free trial action limit. Please upgrade to a paid plan to continue.` }
            </Notification>
          </div>
        ) }
      </When>
    </Modal>
  );
}
