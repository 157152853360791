export const SMSPreview = ({ to, body }) => {
  return (
    <div className={ `w-80 mx-auto bg-gray-900 text-white rounded-lg shadow-lg p-4` }>
      { /* Mobile-like Header */ }
      <div className={ `bg-gray-800 p-2 rounded-t-lg flex justify-between items-center text-sm` }>
        <span className={ `text-gray-400` }>{ `Messages` }</span>
      </div>

      { /* SMS Content */ }
      <div className={ `bg-black p-4 rounded-b-lg space-y-4` }>
        { /* Recipient */ }
        <div className={ `text-sm text-gray-400` }>
          <span>{ `To: ` }</span>
          <span className={ `text-white` }>{ to }</span>
        </div>

        { /* Message Body */ }
        <div className={ `bg-green-500 text-black p-3 rounded-lg max-w-xs break-words` }>
          { body }
        </div>
      </div>
    </div>
  );
};
