import posthog from 'posthog-js';
import { useEffect } from 'react';
import { IntercomProps, useIntercom } from 'react-use-intercom';
import { setUserId } from 'firebase/analytics';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchUserQuery } from '../services/api/userApi/user';
import { analytics } from '../lib/analytics';
import { env } from '../../common/lib/env';
import { mixpanelService } from '../../common/lib/mixpanel';
import { posthogEnabled } from '../../common/lib/posthog';
import { RootState } from '../store';
import { setState } from '../../common/slices/genericState';

export function useUser() {
  const result = useFetchUserQuery({});
  const { boot } = useIntercom();
  const genericState = useSelector((state: RootState) => state.genericState);
  const dispatch = useDispatch();

  const hasBooted = genericState.hasBootedTracking;

  useEffect(() => {
    if (posthog && result.isSuccess && !hasBooted && result.data) {
      const user = result.data;

      const bootData: IntercomProps = {
        email: user.email,
        name: user.name,
        userId: user.id,
        userHash: user.intercomHash,
        customLauncherSelector: `#intercom-chat-start`,
      };

      // only boot intercom in prod, and when not impersonating a user
      if (!user?.adminOverride) {
        boot(bootData);
      }

      if (env.sentryDsn) {
        Sentry.setUser({
          id: user.id,
          email: user.email,
          username: user.name,
        });
      }

      if (analytics) {
        setUserId(analytics, user.id);
      }

      mixpanelService.identify(user.id);

      if (env.isProd && user.id) {
        LogRocket.identify(user.id, {
          email: user.email,
          name: user.name,
        });
      }

      if (posthogEnabled && user.id) {
        const userDetails = {
          email: user.email,
          name: user.name,
          admin_override: user.adminOverride,
        };

        if (env.isDev) {
          console.log(`Posthog identify`, userDetails);
        }

        posthog.identify(user.id, userDetails);
      }

      dispatch(setState({ hasBootedTracking: true }));
    }
  }, [result.data, result.isSuccess, hasBooted]);

  return result;
}
