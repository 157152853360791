import { IAccountingResourcesResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export const accountingResourcesApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchAccountingResources: builder.query<IAccountingResourcesResponse, string>({
      query: organisationId => ({
        url: `accountingApi/resources`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: [
        { type: CacheTags.Accounts, id: `LIST` },
        { type: CacheTags.BrandingThemes, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchAccountingResourcesQuery,
} = accountingResourcesApi;
