import { Fragment, useMemo } from "react";
import { When } from "react-if";
import { Tooltip } from "@mui/material";

import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { classNames } from "../../../../common/lib/classNames";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { Badge } from "../../../../common/Atoms/Badge";

import { IContactTableRow } from "./ContactTable";
import { ContactPersonRow } from "./ContactPersonRow";
import { ContactRowActions } from "./ContactRowActions";

interface IContactTableRowProps {
  row: IContactTableRow;
  selectedIds: number[];
  setSelected: (ids: number[]) => void;
}

export function ContactTableRow({ row, selectedIds, setSelected }: IContactTableRowProps) {
  const selected = useMemo(() => {
    return selectedIds.includes(row.id);
  }, [selectedIds, row.id]);

  function onSelectToggle(e) {
    if (e) {
      e.preventDefault();
    }

    setSelected(
      selected
        ? selectedIds.filter(id => id !== row.id)
        : [...selectedIds, row.id],
    );
  }

  function renderTags() {
    return (
      <div className={ `flex flex-wrap` }>
        {
          row.tags.map(tag => (
            <Badge
              key={ tag + row.id }
              className={ `max-w-fit mr-2` }
              color={ `yellow` }
            >
              { tag }
            </Badge>
          ))
        }
      </div>
    );
  }

  return (
    <Fragment key={ row.name }>
      <tr className={
        classNames(
          `border-t border-gray-400 bg-gray-50 cursor-pointer`,
          selected && `bg-gray-200`,
        )
      }
      onClick={ onSelectToggle }
      >
        <td
          className={ `relative w-12 px-6 sm:w-16 sm:px-8` }
          data-tour={ `contactSelect` }
        >
          <When condition={ selected }>
            <div className={ `absolute inset-y-0 left-0 w-0.5 bg-lateGreen-600` } />
          </When>
          <input
            type={ `checkbox` }
            className={ `absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lateGreen-600 focus:ring-lateGreen-500 sm:left-6 pointer-events-none` }
            checked={ selected }
            onClick={ e => {
              e.stopPropagation();
              e.preventDefault();
            } }
            onChange={ e => {
              e.stopPropagation();
              onSelectToggle(e);
            } }
          />
        </td>
        <th
          colSpan={ 1 }
          scope={ `colgroup` }
          className={ ` py-6 pl-4 pr-3 text-left sm:pl-3` }
        >
          <Heading
            as={ `span` }
            size={ `sm` }
            variant={ `secondary` }
            className={ `flex` }
          >
            { row.name }
            <When condition={ row.frozen }>
              <Tooltip title={ `Contact has been frozen` }>
                <span>
                  <Badge
                    className={ `ml-2 max-w-fit` }
                    color={ `blue` }
                  >
                    { `Frozen` }
                  </Badge>
                </span>
              </Tooltip>
            </When>
          </Heading>
          { /* Tags */ }

          { renderTags() }
        </th>
        <th
          colSpan={ 1 }
          scope={ `colgroup` }
          className={ ` py-3 pl-4 pr-3 text-left sm:pl-3` }
        >
          <Heading
            as={ `span` }
            size={ `sm` }
            variant={ `secondary` }
          >
            { row.group }
          </Heading>
        </th>
        <th
          colSpan={ 1 }
          scope={ `colgroup` }
          className={ ` py-3 pl-4 pr-3 text-left sm:pl-3` }
        >
          <Heading
            as={ `span` }
            size={ `sm` }
            variant={ `secondary` }
          >
            { row.externalAggregateNames?.length ? row.externalAggregateNames.join(`, `) : `-` }
          </Heading>
        </th>
        <th
          colSpan={ 1 }
          scope={ `colgroup` }
          className={ ` py-6 pl-4 pr-3 text-left sm:pl-3` }
        >
          <Heading
            as={ `span` }
            size={ `sm` }
            variant={ `secondary` }
          >
            { row.externalAccountNumber }
          </Heading>
        </th>
        <th>
          <div className={ ` ` }>
            <ContactRowActions contactRow={ row } />
            <Paragraph
              variant={ `secondary` }
              className={ `whitespace-nowrap text-center mt-0.5` }
            >
              { `${row.unpaidInvoiceCount} outstanding invoice${row.unpaidInvoiceCount === 1 ? `` : `s`}` }
            </Paragraph>
          </div>

        </th>
      </tr>
      { row.contactPersons.map((person, personIdx) => (
        <ContactPersonRow
          selected={ selected }
          personRow={ person }
          personIdx={ personIdx }
          parentContact={ row }
          key={ personIdx }
        />
      )) }
    </Fragment>
  );
}
