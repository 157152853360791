import { IStripeAccountUpdate } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';

interface UpdateParams {
  orgId: string;
  stripeAccountId: number;
  update: IStripeAccountUpdate;
}

interface DisconnectParams {
  orgId: string;
  stripeAccountId: number;
}

export const stripeAccountApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    updateStripeAccount: builder.mutation<void, UpdateParams>({
      query: ({ orgId, stripeAccountId, update }) => ({
        url: `stripe-account/${stripeAccountId}`,
        method: `POST`,
        headers: {
          [orgHeader]: orgId,
        },
        data: update,
      }),
      invalidatesTags: [
        { type: CacheTags.Organisations, id: `LIST` },
      ],
    }),
    disconnectStripeAccount: builder.mutation<void,DisconnectParams >({
      query: ({ orgId, stripeAccountId }) => ({
        url: `stripe-account/${stripeAccountId}`,
        method: `DELETE`,
        headers: {
          [orgHeader]: orgId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Organisations, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useUpdateStripeAccountMutation,
  useDisconnectStripeAccountMutation,
} = stripeAccountApi;
