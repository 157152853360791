import { Card } from "../../../../../common/Atoms/Card";
import { CardContent } from "../../../../../common/Atoms/CardContent";
import { ColorPicker } from "../../../../../common/Atoms/ColorPicker";
import { Divider } from "../../../../../common/Atoms/Divider";
import { FormDescription } from "../../../../../common/Atoms/FormDescription";
import { FormLabel } from "../../../../../common/Atoms/FormLabel";
import { Notification } from "../../../../../common/Atoms/Notification";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { SaveAndError } from "../SaveAndError";

export function PortalRoot() {
  const {
    formData,
    onChange,
  } = useGeneralSettingsForm();

  function handleChange(e) {
    onChange(e.name, !formData[e.name]);
  }

  return (
    <Card>
      <CardContent className={ `pt-6` }>
        <TwoColFormRow
          heading={ `Portal Permissions` }
          description={ `Control the default access types for invoice & customer portals` }
        >
          <Toggle
            label={ `Allowed to view all invoices, estimates & quotes?` }
            description={ `When accessed via the invoice / quote portal, can the customer see all other invoices / quotes addressed to them?` }
            name={ `portalViewAllInvoices` }
            checked={ formData.portalViewAllInvoices || false }
            onChange={ handleChange }
          />
        </TwoColFormRow>

        <Divider weight={ `light` }/>

        <TwoColFormRow
          heading={ `Branding` }
          description={ `` }
        >
          <div className={ `flex justify-between` }>
            <div>
              <FormLabel>
                { `Branding Color` }
              </FormLabel>
              <FormDescription>
                { `Set the main color to appear in the portal.` }
              </FormDescription>
            </div>
            <ColorPicker
              value={ formData.primaryColor }
              onChange={ value => onChange(`primaryColor`, value) }
            />
          </div>
        </TwoColFormRow>

        <Divider weight={ `light` }/>

        <TwoColFormRow
          heading={ `Quote Settings` }
          description={ `` }
        >
          <div className={ `space-y-2` }>
            <Toggle
              label={ `Enable PO number` }
              description={ `Allow customers to enter a Purchase Order number upon quote acceptance.` }
              name={ `allowedQuotePOField` }
              checked={ formData.allowedQuotePOField || false }
              onChange={ handleChange }
            />

            <Toggle
              label={ `Enable PO Document Upload` }
              description={ `Allow customers to a Purchase Order document upon quote acceptance.` }
              helpIcon={ `Must be in a PDF format and less than 50MB` }
              name={ `allowedQuotePOUpload` }
              checked={ formData.allowedQuotePOUpload || false }
              onChange={ handleChange }
            />
          </div>
        </TwoColFormRow>

        <Notification
          className={ `mt-6` }
          type={ `info` }
        >
          { `Logo and payment terms/footer are set under general settings` }
        </Notification>
        <SaveAndError
        />
      </CardContent>
    </Card>
  );
}
