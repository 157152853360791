import { useMemo } from 'react';
import { DateFormats, IContactPerson } from 'shared';
import { format } from 'date-fns';
import { When } from 'react-if';
import { useParams } from 'react-router';
import { Tooltip } from '@mui/material';

import { Card } from '../../../common/Atoms/Card';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { useContactParams, useContacts } from '../../hooks/useContacts';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { useSyncContactsMutation } from '../../services/api/contactsApi/contacts';
import { Pagination } from '../../../common/Atoms/Pagination';
import { CardContent } from '../../../common/Atoms/CardContent';
import { useAccountingSystemTerm } from '../../hooks/useAccountingSystemTerm';
import { Notification } from '../../../common/Atoms/Notification';
import { useGroups } from '../../hooks/useGroups';
import { WrappedLink } from '../WrappedLink';
import { Button } from '../../../common/Atoms/Button';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';

import { ContactTable, IContactPersonRow, IContactTableRow } from './ContactTable/ContactTable';
import { ContactFilters } from './ContactTable/ContactFilters';

function createContactPersonRow(contactPerson: IContactPerson): IContactPersonRow {
  const hasNoName = !contactPerson.firstName && !contactPerson.lastName;

  const name = hasNoName ?
    `No Contact Name` :
    `${contactPerson.firstName || ``} ${contactPerson.lastName || `-`}`;

  const hasNoNumber = !contactPerson.mobileNumber && !contactPerson.mobileCountryCode;

  const mobile = hasNoNumber ?
    `` :
    `${contactPerson.mobileCountryCode || ``} ${contactPerson.mobileNumber || ``}`;

  return {
    name: name,
    email: contactPerson.email || `-`,
    mobileNumber: mobile || `-`,
    includeInNotifications: contactPerson.includeInNotifications,
    isPrimary: contactPerson.isPrimary,
  };
}

interface ContactTableProps {
  context?: `group` | `root`;
}

export function Contacts({
  context = `root`,
}: ContactTableProps) {
  const organisation = useGetSelectedOrganisation();
  const { data } = useContacts();
  const { params, updateParams } = useContactParams();
  const [ syncContacts, { isLoading: syncContactsLoading } ] = useSyncContactsMutation();
  const nameOfContact = useAccountingSystemTerm(`Contacts`);
  const { data: groups } = useGroups();
  const { groupId } = useParams();

  const contacts = data?.contacts;
  const total = data?.totalCount;

  const contactRows: IContactTableRow[] = useMemo(() => {
    if (!contacts) return [];

    return contacts.map(contact => {
      const primary = createContactPersonRow(contact.primaryContactPerson);

      const row = {
        id: contact.id,
        externalId: contact.externalId,
        name: contact.name,
        group: contact.groupTitle || `Default`,
        externalAggregateNames: contact.externalAggregateNames,
        externalAccountNumber: contact.externalAccountNumber || `-`,
        unpaidInvoiceCount: contact.unpaidInvoiceCount,
        tags: contact.tags,
        hash: contact.hash,
        frozen: contact.frozen,
        balances: {
          overdue: contact.overdueBalanceDisplay,
          outstanding: contact.outstandingBalanceDisplay,
        },
        contactPersons: [
          // Ensure primary is first
          primary,
          ...contact.contactPersons.map(createContactPersonRow),
        ],
      };

      return row;
    });
  }, [contacts]);

  const showEmptyElement = useMemo(() => {
    if (context === `group` && groupId) {
      const group = groups?.find(g => g.id === Number(groupId));

      if (group && group.contactsCount === 0 && group.archivedContactsCount === 0) {
        return true;
      }
    }

    return false;
  }, [groups, context, groupId]);

  const emptyElement = (
    <Card className={ `w-full flex justify-center` }>
      <div className={ `flex flex-col items-center` }>
        <Paragraph
          className={ `mb-3` }
        >
          { `No contacts found in this group.` }
        </Paragraph>
        <WrappedLink
          to={ `/contacts/manage` }
        >
          <Tooltip title={ `Move contacts to this group on the contact page` }>
            <Button>
              { `Add Contacts` }
            </Button>
          </Tooltip>
        </WrappedLink>
      </div>
    </Card>
  );

  function onContactSync() {
    syncContacts(organisation.id);
  }

  const buttonText = organisation.contactSyncInProgress ?
    `Sync in progress` :
    `Sync with ${organisation.accountingSystemName}`;

  if (showEmptyElement) {
    return emptyElement;
  }

  return (
    <>
      <Card>
        <When condition={ context === `root` }>
          <MultiButtonCardHeader
            title={ `${organisation.accountingSystemName} ` + nameOfContact }
            description={ `Manage your contacts and associated people.` }
            buttonsAsDropdownText={ `Options` }
            buttons={ [
              {
                buttonText,
                onClick: onContactSync,
                loading: syncContactsLoading,
                tooltip: `Start a sync with ${organisation.accountingSystemName}. This may take a while.`,
                caption: organisation.contactsLastSynced && `Last synced: ${format(new Date(organisation.contactsLastSynced), DateFormats.UI_SHORT_TIME)}`,
                disabled: organisation.contactSyncInProgress,
              },
            ] }
          >
            <When condition={ data?.totalVersionOneCount > 0 }>
              <Notification
                type={ `warning` }
                className={ `mt-4 mb-0` }
              >
                {
                  organisation.contactSyncInProgress ?
                    `${data?.totalVersionOneCount} contacts are still waiting to be synced. They will be available shortly.` :
                    `${data?.totalVersionOneCount} contacts have not yet been synced. Please click sync or wait for the next daily sync.`
                }
              </Notification>
            </When>
          </MultiButtonCardHeader>
        </When>
        <CardContent>
          <Pagination
            total={ data?.totalCount || 0 }
            activePage={ params.page }
            onChangePage={ page => updateParams({ page }) }
            limit={ params.limit }
            count={ contacts?.length || 0 }
            className={ `pt-2` }
            perPageOptions={ [ 50, 100, 250, 500 ] }
            onPerPageChange={ limit => updateParams({ limit }) }
            noBorder
          />
          <ContactFilters context={ context }/>
          <ContactTable
            rows={ contactRows }
            context={ context }
          />
          <Pagination
            total={ total || 0 }
            activePage={ params.page }
            onChangePage={ page => updateParams({ page }) }
            limit={ params.limit }
            count={ contacts?.length || 0 }
            className={ `pt-3` }
            perPageOptions={ [ 50, 100, 250, 500 ] }
            onPerPageChange={ limit => updateParams({ limit }) }
          />
        </CardContent>
      </Card>
    </>
  );
}
