import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {  Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { DateFormats } from 'shared';

import { useAuditLogs } from "../../hooks/useAuditLogs";
import { Card } from "../../../common/Atoms/Card";
import { LoadingOverlay } from '../Molecules/LoadingOverlay';
import { Heading } from '../../../common/Atoms/Typography/Heading';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';

import { ChangeDetail } from './ChangeDetail';

export function AuditLogRoot() {
  const { data: auditLogs, isFetching, isLoading } = useAuditLogs();

  const rows = auditLogs || [];

  return (
    <Card>
      <MultiButtonCardHeader
        title={ `Audit Logs` }
        description={ `View recent policy and setting changes. Last 100 results are shown.` }
        noDivider
      />
      <LoadingOverlay
        loading={ isFetching || isLoading }
      >
        <TableContainer>
          <Table sx={ { minWidth: 650 } }
            size={ `small` }
            aria-label={ `audit log table` }>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Heading
                    variant={ `secondary` }
                  >
                    { `Date Changed` }
                  </Heading>
                </TableCell>
                <TableCell align={ `right` }>
                  <Heading
                    variant={ `secondary` }
                  >{ `Log Type` }
                  </Heading>
                </TableCell>
                <Tooltip title={ `Which user made this change` }>
                  <TableCell align={ `right` }>
                    <Heading
                      variant={ `secondary` }
                    >{ `User` }
                    </Heading>
                  </TableCell>
                </Tooltip>
                <TableCell align={ `right` }>
                  <Heading
                    variant={ `secondary` }
                  >
                    { `Message` }
                  </Heading>
                </TableCell>
                <TableCell align={ `right` }>
                  <Heading
                    variant={ `secondary` }
                  >

                    { `Change Detail` }
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { rows.map(row => (
                <TableRow
                  key={ row.id }
                  sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                >
                  <TableCell component={ `th` }
                    scope={ `row` }>
                    { format(new Date(row.timestamp), DateFormats.UI_SHORT_TIME) }
                  </TableCell>
                  <TableCell align={ `right` }>
                    <Tooltip title={ row.id }>
                      <span className={ `flex flex-col` }>
                        {
                          row.auditLogEntryType
                        }
                        <Paragraph
                          variant={ `help` }
                          as={ `span` }
                        >
                          {
                            row.title ? `(${row.title})` : ``
                          }
                        </Paragraph>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align={ `right` }>
                    {
                      row.wasAdminOverride ? `Paidnice Admin` : row.userEmail
                    }
                  </TableCell>
                  <TableCell align={ `right` }>{ row.userMessage }</TableCell>
                  <TableCell align={ `right` }>
                    <ChangeDetail
                      entry={ row }
                    />
                  </TableCell>
                </TableRow>
              )) }
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingOverlay>
    </Card>
  );
}

